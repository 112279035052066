import React, { useEffect } from "react";
import { useState } from "react";
import {
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
} from "@mui/material";

import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AddOrUpdateTehsil from "./AddOrUpdateTehsil";
import {
  _delete_tehsil_from_district,
  _list_of_tehsil,
} from "../../DAL/Districts/districts";
import { useSnackbar } from "notistack";

const TehsilList = ({
  rowData,
  tehsilModalState,
  setTehsilModalState,
  districtList,
  setDistrictList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tehsilData, setTehsilData] = useState(null);
  const [deleteData, setDeleteData] = useState("");
  const [tehsilList, setTehsilList] = useState([]);

  const handleAgreeDelete = (row) => {
    setOpenDelete(true);
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_tehsil_from_district(
      rowData?._id,
      deleteData?._id
    );
    if (result.code === 200) {
      const newList = tehsilList.filter(
        (target) => target._id !== deleteData?._id
      );
      setTehsilList(newList);

      const newDistrictList = districtList.map((item) => {
        if (item._id === rowData?._id) {
          return {
            ...item,
            number_of_tehsils:
              item.number_of_tehsils > 0
                ? item.number_of_tehsils - 1
                : item.number_of_tehsils,
          };
        }
        return item;
      });

      setDistrictList(newDistrictList);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleClickEditTehsil = (row) => {
    setTehsilModalState(true);
    setTehsilData(row);
  };

  const get_tehsil_list = async () => {
    setIsLoading(true);
    const result = await _list_of_tehsil(rowData?._id);

    if (result.code === 200) {
      setTehsilList(result.list_of_tehsils);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      get_tehsil_list();
    }
  }, [rowData?._id]);

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Tehsil?"}
        handleAgree={handleDelete}
      />

      <AddOrUpdateTehsil
        modalState={tehsilModalState}
        setModalState={setTehsilModalState}
        rowData={rowData}
        rowDataTehsil={tehsilData}
        tehsilList={tehsilList}
        setTehsilList={setTehsilList}
      />

      <div className="popover-mid-container">
        <div className="spacing">
          <div className="row-spacing">
            <Paper
              elevation={3}
              style={{ width: "100%", padding: "10px", boxShadow: "none" }}
            >
              {isLoading ? (
                <div className="p-3">
                  <Typography variant="h5" align="center">
                    <CircularProgress />
                  </Typography>
                </div>
              ) : tehsilList.length == 0 ? (
                <div className="p-3">
                  <Typography variant="h5" align="center">
                    No Data Exist
                  </Typography>
                </div>
              ) : (
                <List>
                  {tehsilList.map((city, index) => (
                    <ListItem
                      key={index}
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        marginBottom:
                          index !== tehsilList.length - 1 ? "8px" : "0", // No margin for the last item
                        padding: "10px 14px",
                      }}
                      secondaryAction={
                        <div>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            className="tehsil-edit-icon"
                            onClick={() => handleClickEditTehsil(city)}
                          >
                            <Iconify
                              icon={"carbon:edit"}
                              width={20}
                              height={20}
                            />
                          </IconButton>

                          <IconButton
                            edge="end"
                            aria-label="delete"
                            className="tehsil-delete-icon"
                            onClick={() => handleAgreeDelete(city)}
                          >
                            <Iconify
                              icon={"material-symbols:delete-outline"}
                              width={20}
                              height={20}
                            />
                          </IconButton>
                        </div>
                      }
                    >
                      <ListItemText
                        primary={
                          <span className="capitalized typography-color-in-table">
                            {city.tehsil_name_en}
                            {city.tehsil_name_ur && (
                              <span className="font-family-urdu">
                                ( {city.tehsil_name_ur} )
                              </span>
                            )}
                          </span>
                        }
                        className="tehsil-list"
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default TehsilList;
