import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardLayout, LoginLayout } from "../layouts";
import { Categories, Dashboard } from "../pages";
import Login from "../pages/Login/Login";
import Crops from "../pages/Crops/Crops";
import Districts from "../pages/Districts/Districts";
import Companies from "../pages/Companies/Companies";
import KnowledgeBank from "../pages/KnowledgeBank/KnowledgeBank";
import AgriConsultancy from "../pages/AgriConsultancy/AgriConsultancy";
import SalesMarket from "../pages/SalesMarket/SalesMarket";
import Farmers from "../pages/Farmers/Farmers";
import RentalMachinery from "../pages/RentalMachinery/RentalMachinery";
import Services from "../pages/Services/Services";

const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  } else {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Routers() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/crops" element={<Crops />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/districts" element={<Districts />} />
        <Route path="/knowledge-bank" element={<KnowledgeBank />} />
        <Route path="/agri-consultancy" element={<AgriConsultancy />} />
        <Route path="/sales-market" element={<SalesMarket />} />
        <Route path="/farmers" element={<Farmers />} />
        <Route path="/rental-machinery" element={<RentalMachinery />} />
        <Route path="/services" element={<Services />} />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}
