import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { STATUS } from "../../utils/constant";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Avatar, Button } from "@mui/material";
import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AddOrUpdateCategory from "./AddOrUpdateCategory";
import { useSnackbar } from "notistack";
import {
  _delete_category,
  _delete_selected_category,
  _list_of_category,
  _parent_category_list,
} from "../../DAL/Categories/categories";
import { imageBaseUrl } from "../../config/config";
import CircularLoader from "../../components/loaders/CircularLoader";
import CustomDrawer from "../../components/CustomDrawer";
import FilterCategories from "./components/FilterCategories";
import CustomImageAvatar from "../../components/CustomImageAvatar";

const emptyFilter = {
  status: "all",
  parent_category_id: "all",
};

const Categories = () => {
  const { setnavbarTitle } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);

  const TABLE_HEAD = [
    {
      id: "category_name_en",
      label: "CATEGORY",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center mb-0 capitalized">
              <CustomImageAvatar
                imageUrl={row?.image?.thumbnail_2}
                altText={row.category_name_en}
              />
              <span>
                {row.category_name_en}{" "}
                {row.category_name_ur && (
                  <span className="font-family-urdu">
                    ( {row.category_name_ur} )
                  </span>
                )}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "parent_category",
      label: "PARENT CATEGORY",
      className: "typography-color-in-table",
      renderData: (row) => {
        let parent_category_name_en = "";
        let parent_category_name_ur = "";

        if (row.parent_category?.parent_category_name_en) {
          parent_category_name_en =
            row.parent_category?.parent_category_name_en;
        }

        if (row.parent_category?.parent_category_name_ur) {
          parent_category_name_ur =
            row.parent_category?.parent_category_name_ur;
        }

        if (parent_category_name_en === "" && parent_category_name_ur === "") {
          parent_category_name_en = "None";
        }
        return (
          <>
            <div className="d-flex align-items-center mb-0 capitalized">
              <span>
                {parent_category_name_en}{" "}
                {parent_category_name_ur && (
                  <span className="font-family-urdu">
                    ( {parent_category_name_ur} )
                  </span>
                )}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "order",
      label: "ORDER",
      className: "typography-color-in-table",
    },

    {
      id: "updatedAt",
      label: "CREATED AT",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center mb-0">
              <span className="capitalized">
                {moment(row.updatedAt).format("DD MMMM YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    { id: "action", label: "ACTION", type: "action" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_category_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleClickEdit = (row) => {
    setModalState(true);
    setRowData(row);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setModalState(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_category(deleteData?._id);
    if (result.code === 200) {
      const newList = categoryList.filter(
        (target) => target._id !== deleteData?._id
      );

      const updatedList = newList.map((item, index) => ({
        ...item,
        order: index + 1,
      }));

      setCategoryList(updatedList);
      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      category_ids: selectedIDS,
    };

    const result = await _delete_selected_category(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_category_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_parent_category_list = async () => {
    // if (parentCategoryList.length > 0) {
    //   return;
    // }

    const result = await _parent_category_list();

    if (result.code === 200) {
      setParentCategoryList(result.parent_category);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_category_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      parent_category_id: data.parent_category_id,
      status: data.status,
    };

    if (postData.status === "all") {
      delete postData.status;
    }
    if (postData.parent_category_id === "all") {
      delete postData.parent_category_id;
    }

    setIsLoading(true);
    const result = await _list_of_category(page, rowsPerPage, postData);

    if (result.code === 200) {
      setCategoryList(result.list_of_category);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    get_category_list();
  };

  useEffect(() => {
    get_category_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Categories");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <FilterCategories
              filterInputs={filterInputs}
              handleFilterChange={handleFilterChange}
              onCloseDrawer={onCloseDrawer}
              handleResetFilter={handleResetFilter}
              handleFilterSubmit={searchFunction}
              parentCategoryList={parentCategoryList}
            />
          </>
        }
      />
      <DeleteConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete this Category?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Categories?"}
        handleAgree={handleSelectedDelete}
      />

      <AddOrUpdateCategory
        modalState={modalState}
        setModalState={setModalState}
        rowData={rowData}
        categoryList={categoryList}
        setCategoryList={setCategoryList}
        setTotalCount={setTotalCount}
        parentCategoryList={parentCategoryList}
        get_parent_category_list={get_parent_category_list}
        get_category_list={get_category_list}
      />

      <div className="mt-2">
        <div className="d-flex   justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={() => {
                handleAgreeSelectedDelete();
              }}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="hugeicons:filter"
              />
            }
            onClick={() => {
              get_parent_category_list();
              onOpenDrawer();
            }}
            className="capitalized me-3 button-in-listing"
          >
            Filter
          </Button>

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              setRowData(null);
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New Category
          </Button>
        </div>

        <CustomTable
          data={categoryList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
    </>
  );
};

export default Categories;
