import React, { useEffect, useState } from "react";
import AppHeader from "./AppHeader";
import AppSideBar from "./AppSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Logo } from "../../assets";
import { useSnackbar } from "notistack";
import { useAdminContext } from "../../Hooks/AdminContext";
import { _set_user_in_localStorage } from "../../local_storage/local_storage";
import { _admin_init_with_token } from "../../DAL/Login/login";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DashboardLayout() {
  const classes = useStyles();
  const pathname = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setUserInfo } = useAdminContext();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleInit = async () => {
    const result = await _admin_init_with_token();
    if (result.code === 200) {
      setUserInfo(result.admin);
      _set_user_in_localStorage(result.admin);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      setIsLoading(false);
    } else {
      handleInit();
    }
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <Helmet>
        <title>{"FAO"}</title>
        <meta name="description" content={"FAO"} />
        <link rel="icon" href={Logo} />
      </Helmet>
      <div className="d-flex position-relative">
        <AppHeader
          handleDrawerToggle={handleDrawerToggle}
          setIsLoading={setIsLoading}
        />
        <AppSideBar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </div>
    </>
  );
}
