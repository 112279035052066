import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { STATUS, truncateString } from "../../utils/constant";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Button, Tooltip } from "@mui/material";
import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AddOrUpdateBuyer from "./AddOrUpdateBuyer";
import { useSnackbar } from "notistack";
import {
  _delete_sales_market,
  _delete_selected_sales_market,
  _list_of_sales_market,
} from "../../DAL/SalesMarket/sales_market";
import CustomDrawer from "../../components/CustomDrawer";
import FilterSalesMarket from "./components/FilterSalesMarket";
import CircularLoader from "../../components/loaders/CircularLoader";
import { _listing_apis_for_single_screen } from "../../DAL/Common/common";
import MultipleItemsShowList from "../../components/MultipleItemsShowList";
import CustomImageAvatar from "../../components/CustomImageAvatar";

const emptyFilter = {
  status: "all",
  district_id: "all",
  tehsil_id: "all",
  crop_id: "all",
};

const SalesMarket = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setnavbarTitle } = useAdminContext();
  const [salesMarketList, setSalesMarketList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);
  const [allDistrictList, setAllDistrictList] = useState([]);
  const [allCropList, setAllCropList] = useState([]);
  const [selectedCropsName, setSelectedCropsName] = useState([]);
  const [showMultiItems, setShowMultiItems] = useState(false);
  const [showMultiItemsData, setshowMultiItemsData] = useState(null);

  const CustomTooltipTitle = ({ title }) => {
    return <span className="font-family-urdu">{title}</span>;
  };

  const TABLE_HEAD = [
    {
      id: "buyer_name",
      label: "BUYERS",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className=" d-flex align-items-center mb-0">
              <CustomImageAvatar
                imageUrl={row?.image?.thumbnail_2}
                altText={row.buyer_name}
              />
              <span className="font-family-urdu  ">{row.buyer_name} </span>
            </div>
          </>
        );
      },
    },
    {
      id: "phone_number",
      label: "PHONE NUMBER",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <span className="mb-0 mt-2">
            <span>+</span>
            {row?.phone_number}
          </span>
        );
      },
    },
    {
      id: "description",
      label: "FURTHER INFORMATION",
      className: "typography-color-in-table",
      renderData: (row) => {
        let description = "";
        let descriptionLength = 0;
        if (row?.description) {
          descriptionLength = row.description.length;
          description = row.description;
        }
        return (
          <span className="font-family-urdu">
            {description ? (
              <Tooltip
                title={
                  descriptionLength > 25 ? (
                    <CustomTooltipTitle title={description} />
                  ) : (
                    ""
                  )
                }
                arrow
                placement="top"
              >
                <span
                  className="pointer capitalized"
                  style={{ fontWeight: 500 }}
                >
                  {truncateString(description, 25)}
                </span>
              </Tooltip>
            ) : (
              <span>N/A</span>
            )}
          </span>
        );
      },
    },

    {
      id: "joining_date",
      label: "JOINING DATE",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className=" d-flex align-items-center mb-0">
              <span className="capitalized ">
                {moment(row.joining_date).format("DD MMMM YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "district",
      label: "DISTRICT",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <div className="d-flex align-items-center mb-0">
            {row?.district?.district_name_ur && row?.tehsil?.tehsil_name_ur ? (
              <span className=" font-family-urdu ">
                {row?.district?.district_name_ur} ,{" "}
                {row?.tehsil?.tehsil_name_ur}
              </span>
            ) : (
              <span className="typography-color-in-table">
                {row?.district?.district_name_en} ,{" "}
                {row?.tehsil?.tehsil_name_en}
              </span>
            )}
          </div>
        );
      },
    },

    {
      id: "crops",
      label: "CROPS",
      renderData: (row) => {
        const crops = row.crops;
        let firstCrop = crops[0]?.crop_name_en;
        if (crops[0]?.crop_name_ur) {
          firstCrop = crops[0]?.crop_name_ur;
        }

        const additionalCropsCount =
          crops.length > 1 ? ` +${crops.length - 1}` : "";

        return (
          <div className=" d-flex align-items-center mb-0 sales-market-count">
            <span className="sales-market-chip font-family-urdu">
              {firstCrop}
            </span>
            <span
              className="pointer"
              onClick={() => multipleItemsShowList(crops)}
            >
              {additionalCropsCount}
            </span>
          </div>
        );
      },
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    {
      id: "action",
      label: "ACTION",
      type: "action",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_sales_market_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };
  const multipleItemsShowList = (row) => {
    setShowMultiItems(true);
    setshowMultiItemsData(row);
  };

  const handleClickEdit = (row) => {
    setModalState(true);
    setRowData(row);
    setSelectedCropsName(row?.crops);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setRowData(null);
    setModalState(true);
    setSelectedCropsName([]);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_sales_market(deleteData?._id);
    if (result.code === 200) {
      const newList = salesMarketList.filter(
        (target) => target._id !== deleteData?._id
      );
      setSalesMarketList(newList);
      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      sales_market_ids: selectedIDS,
    };

    const result = await _delete_selected_sales_market(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_sales_market_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_all_district_crop_list = async () => {
    if (allCropList.length > 0 && allDistrictList.length > 0) {
      return;
    }

    const result = await _listing_apis_for_single_screen({
      listing: ["district", "crop"],
    });

    if (result.code === 200) {
      setAllDistrictList(result.districts);
      const convertedCropsObject = result.crops.map((crop) => {
        return {
          crop_id: crop._id,
          crop_name_en: crop.crop_name_en,
          crop_name_ur: crop.crop_name_ur,
        };
      });

      setAllCropList(convertedCropsObject);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_sales_market_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      status: data.status,
      district_id: data.district_id,
      tehsil_id: data.tehsil_id,
      crop_id: data.crop_id,
    };

    if (postData.status === "all") {
      delete postData.status;
    }

    if (postData.district_id === "all") {
      delete postData.district_id;
    }

    if (postData.tehsil_id === "all") {
      delete postData.tehsil_id;
    }

    if (postData.crop_id === "all") {
      delete postData.crop_id;
    }

    setIsLoading(true);
    const result = await _list_of_sales_market(page, rowsPerPage, postData);

    if (result.code === 200) {
      setSalesMarketList(result.list_of_sales_market);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    get_sales_market_list();
  };

  useEffect(() => {
    get_sales_market_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Sales Market");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <MultipleItemsShowList
        dataList={showMultiItemsData ? showMultiItemsData : []}
        modalState={showMultiItems}
        setModalState={setShowMultiItems}
        title={"All Crops"}
        nameEn={"crop_name_en"}
        nameUr={"crop_name_ur"}
      />

      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <FilterSalesMarket
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              handleFilterChange={handleFilterChange}
              onCloseDrawer={onCloseDrawer}
              handleResetFilter={handleResetFilter}
              handleFilterSubmit={searchFunction}
              allDistrictList={allDistrictList}
              allCropList={allCropList}
              get_all_district_crop_list={get_all_district_crop_list}
            />
          </>
        }
      />

      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Buyer?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Buyers?"}
        handleAgree={handleSelectedDelete}
      />

      <AddOrUpdateBuyer
        modalState={modalState}
        setModalState={setModalState}
        rowData={rowData}
        setTotalCount={setTotalCount}
        get_all_district_crop_list={get_all_district_crop_list}
        allDistrictList={allDistrictList}
        allCropList={allCropList}
        selectedCropsName={selectedCropsName}
        setSelectedCropsName={setSelectedCropsName}
        salesMarketList={salesMarketList}
        setSalesMarketList={setSalesMarketList}
      />

      <div className="mt-2">
        <div className="d-flex   justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={() => {
                handleAgreeSelectedDelete();
              }}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="hugeicons:filter"
              />
            }
            onClick={() => {
              onOpenDrawer();
            }}
            className="capitalized me-3 button-in-listing"
          >
            Filter
          </Button>

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New Buyer
          </Button>
        </div>

        <CustomTable
          data={salesMarketList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
    </>
  );
};

export default SalesMarket;
