import React from "react";
import { TextField, MenuItem } from "@mui/material";
import Iconify from "../../../components/Iconify";

const FilterCategories = ({
  filterInputs,
  handleFilterChange,
  handleResetFilter,
  onCloseDrawer,
  handleFilterSubmit,
  parentCategoryList,
}) => {
  return (
    <div className="container mt-4">
      <div className="spacing">
        <div className="row-spacing">
          <TextField
            fullWidth
            label="Choose Parent Category"
            select
            value={filterInputs.parent_category_id}
            onChange={handleFilterChange}
            name="parent_category_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">None</MenuItem>
            {parentCategoryList.map((category) => (
              <MenuItem
                className="capitalized"
                key={category._id}
                value={category._id}
              >
                {category.category_name_en}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="Status"
            select
            value={filterInputs.status}
            onChange={handleFilterChange}
            name="status"
            className="text-field-border"
          >
            <MenuItem value="all">None</MenuItem>
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              onCloseDrawer();
              handleFilterSubmit(e);
            }}
          >
            <Iconify
              icon="material-symbols:filter-alt"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Filter
          </button>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              handleResetFilter();
              onCloseDrawer();
            }}
          >
            <Iconify
              icon="material-symbols:clear-all-rounded"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterCategories;
