import React, { useEffect } from "react";
import { useState } from "react";
import CustomPopover from "../../components/CustomPopover";
import { useSnackbar } from "notistack";
import {
  _add_tehsil_in_district,
  _update_tehsil_in_district,
} from "../../DAL/Districts/districts";
import AddOrUpdateTehsilComponent from "./components/AddOrUpdateTehsilComponent";
import AddMultiTehsilComponent from "./components/AddMultiTehsilComponent";

const emptyFormInputs = {
  district_name_en: "",
  district_name_ur: "",
  tehsil_name_en: "",
  tehsil_name_ur: "",
};

const AddOrUpdateTehsil = ({
  modalState,
  setModalState,
  rowData,
  rowDataTehsil,
  tehsilList,
  setTehsilList,
  districtList,
  setDistrictList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([
    { tehsil_name_en: "", tehsil_name_ur: "" },
  ]);

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const updateTehsilCountInDistrictList = () => {
    const newList = districtList.map((item) => {
      if (item._id === rowData?._id) {
        return {
          ...item,
          number_of_tehsils: item.number_of_tehsils + additionalFields.length,
        };
      }
      return item;
    });
    setDistrictList(newList);
    handleRefresh();
  };

  const updateListObject = (rowDataTehsil, postData) => {
    let updatedObject = {
      _id: rowDataTehsil._id,
      tehsil_name_en: postData.tehsil_name_en,
      tehsil_name_ur: postData.tehsil_name_ur,
    };

    const newList = tehsilList.map((item) => {
      if (item._id === rowDataTehsil._id) {
        return updatedObject;
      }
      return item;
    });

    setTehsilList(newList);
  };

  const handleRefresh = () => {
    setFormInputs(emptyFormInputs);
    setAdditionalFields([{ tehsil_name_en: "", tehsil_name_ur: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      rowDataTehsil &&
      !formInputs.tehsil_name_en &&
      !formInputs.tehsil_name_ur
    ) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    let postData = {
      tehsil_name_en: formInputs.tehsil_name_en,
      tehsil_name_ur: formInputs.tehsil_name_ur,
    };

    setSubmitButtonLoader(true);
    const result = rowDataTehsil
      ? await _update_tehsil_in_district(
          rowData?._id,
          rowDataTehsil?._id,
          postData
        )
      : await _add_tehsil_in_district(rowData?._id, {
          tehsils: additionalFields,
        });

    if (result.code === 200) {
      if (rowDataTehsil) {
        updateListObject(rowDataTehsil, postData);
        handleRefresh();
      } else {
        updateTehsilCountInDistrictList();
      }
      setModalState(false);
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowDataTehsil) {
      setFormInputs({
        district_name_en: rowData?.district_name_en,
        district_name_ur: rowData?.district_name_ur,
        tehsil_name_en: rowDataTehsil?.tehsil_name_en,
        tehsil_name_ur: rowDataTehsil?.tehsil_name_ur,
      });
    }
  }, [rowData?._id, modalState, rowDataTehsil]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowDataTehsil ? "Edit Tehsil" : "Add New Tehsil"}
        submitButtonText={rowDataTehsil ? "Update" : "Add"}
        showStatus={false}
        handleSubmit={handleSubmit}
        submitButtonLoader={submitButtonLoader}
        width="710px"
        XSToMdScreen="90%"
        componentToPassDown={
          <>
            {rowDataTehsil ? (
              <AddOrUpdateTehsilComponent
                formInputs={formInputs}
                handleChange={handleChange}
              />
            ) : (
              <AddMultiTehsilComponent
                formInputs={formInputs}
                additionalFields={additionalFields}
                setAdditionalFields={setAdditionalFields}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateTehsil;
