import React, { useEffect } from "react";
import { useState } from "react";
import { TextField } from "@mui/material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";

const AddOrUpdateComponent = ({ formInputs, handleChange, rowData }) => {
  const [nameEnglishRequired, setNameEnglishRequired] = useState(true);
  const [nameUrduRequired, setNameUrduRequired] = useState(true);

  useEffect(() => {
    if (!formInputs.service_name_en && !formInputs.service_name_ur) {
      setNameEnglishRequired(true);
      setNameUrduRequired(true);
    } else if (!formInputs.service_name_en) {
      setNameEnglishRequired(false);
      setNameUrduRequired(true);
    } else if (!formInputs.service_name_ur) {
      setNameEnglishRequired(true);
      setNameUrduRequired(false);
    }
  }, [formInputs]);

  return (
    <div className="popover-mid-container">
      <div className="spacing">
        <div className="row-spacing">
          <TextField
            fullWidth
            label="Service Name"
            value={formInputs.service_name_en}
            onChange={handleChange}
            name="service_name_en"
            className="text-field-border"
            required={nameEnglishRequired}
          />

          <CustomTextFieldUrdu
            label="سروس کا نام"
            value={formInputs.service_name_ur}
            onChange={handleChange}
            name="service_name_ur"
            placeholder="سروس کا نام"
            required={nameUrduRequired}
          />
        </div>
        {rowData && (
          <div className="row-spacing">
            <TextField
              fullWidth
              label="Order"
              value={formInputs.order}
              onChange={handleChange}
              name="order"
              type="number"
              className="text-field-border"
              required
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOrUpdateComponent;
