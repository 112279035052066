import React from "react";
import { TextField, Avatar, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";
import Editor from "../../../components/editor/Editor";
import { Button, FormHelperText } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});

const AddOrUpdateComponent = ({
  formInputs,
  setFormInputs,
  previewUrl,
  audioUrl,
  handleFile,
  handleChange,
  rowData,
}) => {
  const [playerKey, setPlayerKey] = useState(0);

  useEffect(() => {
    setPlayerKey((prevKey) => prevKey + 1);
  }, [audioUrl]);

  return (
    <>
      <div className="popover-mid-container">
        <div className="spacing">
          <div className="row-spacing">
            <CustomTextFieldUrdu
              label="Title"
              value={formInputs.title}
              onChange={handleChange}
              name="title"
              labelDirection="left"
              direction=""
              required
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Media Type"
              select
              value={formInputs.content_type}
              onChange={handleChange}
              name="content_type"
              className="text-field-border"
              required
            >
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="audio">Audio</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </TextField>
          </div>

          {formInputs.content_type !== "other" ? (
            <div>
              <div className="d-flex justify-content-between align-items-end">
                <div>
                  {" "}
                  <FormHelperText className="pt-0">
                    Upload Your{" "}
                    {formInputs.content_type == "audio" ? "Audio" : "Image"}{" "}
                    file.
                  </FormHelperText>
                </div>

                <div>
                  {previewUrl && formInputs.content_type === "image" ? (
                    <Avatar
                      alt="image"
                      src={previewUrl}
                      sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                      variant="square"
                      className="table-image-with-name"
                    />
                  ) : null}
                </div>

                <div>
                  <p
                    className="assignment_sub_heading_in_add_edit"
                    style={{ marginBottom: "3px" }}
                  ></p>
                  <label htmlFor="contained-button-file">
                    <Input
                      accept={
                        formInputs.content_type === "audio"
                          ? "audio/mpeg,audio/wav,audio/ogg,audio/aac,audio/flac"
                          : "image/*"
                      }
                      id="contained-button-file"
                      type="file"
                      name="image"
                      onChange={handleFile}
                    />

                    <Button
                      id="contained-button-file"
                      className="small-outlined-button"
                      variant="outlined"
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      {"Upload"}
                    </Button>
                  </label>
                </div>
              </div>
              <hr />
            </div>
          ) : (
            <div className="row-spacing">
              <TextField
                fullWidth
                label="Video Link"
                value={formInputs.other_content}
                onChange={handleChange}
                name="other_content"
                className="text-field-border"
                required
              />
            </div>
          )}

          {audioUrl && formInputs.content_type === "audio" ? (
            <div style={{ marginTop: "-3px", marginBottom: "-7px" }}>
              <audio key={playerKey} className="audio-player" controls>
                <source src={`${audioUrl}`} type="audio/ogg" />
                <source src={`${audioUrl}`} type="audio/mpeg" />
              </audio>
            </div>
          ) : null}

          {rowData && (
            <div className="row-spacing">
              <TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                type="number"
                className="text-field-border"
                required
              />
            </div>
          )}

          <div className="row-spacing">
            <Editor
              placeholder="Description"
              value={
                formInputs &&
                (formInputs?.description !== undefined
                  ? formInputs?.description
                  : "")
              }
              onChange={(value) =>
                setFormInputs({
                  ...formInputs,
                  description: value,
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
