import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";

const AddOrUpdateComponent = ({ formInputs, handleChange }) => {
  const [districtNameEnglishRequired, setDistrictNameEnglishRequired] =
    useState(true);
  const [districtNameUrduRequired, setDistrictNameUrduRequired] =
    useState(true);

  useEffect(() => {
    if (!formInputs.district_name_en && !formInputs.district_name_ur) {
      setDistrictNameEnglishRequired(true);
      setDistrictNameUrduRequired(true);
    } else if (!formInputs.district_name_en) {
      setDistrictNameEnglishRequired(false);
      setDistrictNameUrduRequired(true);
    } else if (!formInputs.district_name_ur) {
      setDistrictNameEnglishRequired(true);
      setDistrictNameUrduRequired(false);
    }
  }, [formInputs]);

  return (
    <>
      <div className="popover-mid-container">
        <div className="spacing">
          <div className="row-spacing">
            <TextField
              fullWidth
              label="District Name"
              value={formInputs.district_name_en}
              onChange={handleChange}
              name="district_name_en"
              className="text-field-border"
              required={districtNameEnglishRequired}
            />

            <CustomTextFieldUrdu
              label="ضلع کا نام"
              value={formInputs.district_name_ur}
              onChange={handleChange}
              name="district_name_ur"
              placeholder="ضلع کا نام"
              required={districtNameUrduRequired}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
