import React, { useEffect } from "react";
import { useState } from "react";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import { useSnackbar } from "notistack";
import CustomPopover from "../../components/CustomPopover";
import { imageBaseUrl } from "../../config/config";
import {
  _add_rental_machinery,
  _update_rental_machinery,
} from "../../DAL/RentalMachinery/rental_machinery";

const emptyFormInputs = {
  service_provider_name_en: "",
  service_provider_name_ur: "",
  description: "",
  district: null,
  district_id: "",
  tehsil: null,
  tehsil_id: "",
};

const AddOrUpdateServiceProvider = ({
  modalState,
  setModalState,
  rowData,
  rentalMachineryList,
  setRentalMachineryList,
  setTotalCount,
  allDistrictList,
  get_all_district_service_list,
  allServiceList,
  selectedServicies,
  setSelectedServicies,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  function handleFile(e) {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  }

  function handleRemoveImage() {
    setImage(null);
    setPreviewUrl("");
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const updateListObject = (newItem) => {
    const newList = rentalMachineryList.map((item) => {
      if (item._id === rowData?._id) {
        return newItem;
      }
      return item;
    });
    setRentalMachineryList(newList);
  };

  const addNewObject = (newItem) => {
    setRentalMachineryList((prevItemList) => [newItem, ...prevItemList]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
    setPreviewUrl("");
    setPhoneNumber("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formInputs.service_provider_name_en &&
      !formInputs.service_provider_name_ur
    ) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    if (!formInputs.district_id) {
      enqueueSnackbar("District is required", { variant: "error" });
      return;
    }

    if (!formInputs.tehsil_id) {
      enqueueSnackbar("Tehsil is required", { variant: "error" });
      return;
    }

    if (!phoneNumber) {
      enqueueSnackbar("Phone Number is required", { variant: "error" });
      return;
    }

    if (!selectedServicies || selectedServicies.length === 0) {
      enqueueSnackbar("Service is required", { variant: "error" });
      return;
    }

    let districtObject = {
      district_id: formInputs?.district?._id,
      district_name_en: formInputs?.district?.district_name_en,
      district_name_ur: formInputs?.district?.district_name_ur,
    };

    let tehsilObject = {
      tehsil_id: formInputs.tehsil?._id
        ? formInputs.tehsil?._id
        : formInputs.tehsil?.tehsil_id,
      tehsil_name_en: formInputs.tehsil?.tehsil_name_en,
      tehsil_name_ur: formInputs.tehsil?.tehsil_name_ur,
    };

    const formData = new FormData();
    formData.append("status", isStatusActive);
    formData.append("phone_number", phoneNumber);
    formData.append("services", JSON.stringify(selectedServicies));
    formData.append("description", formInputs.description);
    formData.append("district", JSON.stringify(districtObject));
    formData.append("tehsil", JSON.stringify(tehsilObject));
    formData.append(
      "service_provider_name_en",
      formInputs.service_provider_name_en
    );
    formData.append(
      "service_provider_name_ur",
      formInputs.service_provider_name_ur
    );

    if (image) {
      formData.append("image", image);
    }

    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_rental_machinery(rowData?._id, formData)
      : await _add_rental_machinery(formData);

    if (result.code === 200) {
      const newData = result.rental_machinery;

      if (rowData?._id) {
        updateListObject(newData);
        setModalState(false);
      } else {
        addNewObject(newData);
        setModalState(false);
      }
      handleRefresh();
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      let district = rowData?.district;

      let newDistrictObj = {
        _id: district.district_id,
        district_name_en: district.district_name_en,
        district_name_ur: district.district_name_ur,
      };

      setIsStatusActive(rowData?.status);
      setFormInputs({
        ...formInputs,
        service_provider_name_en: rowData?.service_provider_name_en,
        service_provider_name_ur: rowData?.service_provider_name_ur,
        description: rowData?.description,
        district: newDistrictObj,
        district_id: newDistrictObj._id,
        tehsil: rowData?.tehsil,
        tehsil_id: rowData?.tehsil.tehsil_id,
      });
      setPhoneNumber(rowData?.phone_number);
      setSelectedServicies(rowData?.services ? rowData?.services : []);
      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
    }

    if (modalState) {
      get_all_district_service_list();
    }
  }, [rowData?._id, modalState, rowData]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={
          rowData?._id ? "Edit Agri Consultancy" : "Add New Agri Consultancy"
        }
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        handleSubmit={handleSubmit}
        submitButtonLoader={submitButtonLoader}
        width={"600px"}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              previewUrl={previewUrl}
              handleFile={handleFile}
              handleChange={handleChange}
              allDistrictList={allDistrictList}
              handleRemoveImage={handleRemoveImage}
              allServiceList={allServiceList}
              selectedServicies={selectedServicies}
              setSelectedServicies={setSelectedServicies}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateServiceProvider;
