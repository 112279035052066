import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { STATUS } from "../../utils/constant";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Button } from "@mui/material";
import CustomPopover from "../../components/CustomPopover";
import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AddOrUpdateDistrict from "./AddOrUpdateDistrict";
import AddOrUpdateTehsil from "./AddOrUpdateTehsil";
import TehsilList from "./TehsilList";
import { useSnackbar } from "notistack";
import {
  _delete_district,
  _delete_selected_district,
  _list_of_districts,
} from "../../DAL/Districts/districts";
import CustomDrawer from "../../components/CustomDrawer";
import CircularLoader from "../../components/loaders/CircularLoader";
import FilterDistricts from "./components/FilterDistricts";

const emptyFilter = {
  status: "all",
  district_id: "all",
};

const Districts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setnavbarTitle } = useAdminContext();
  const [districtList, setDistrictList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [tehsilModalState, setTehsilModalState] = useState(false);
  const [tehsilListModalState, setTehsilListModalState] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [tehsilModalEditState, setTehsilModalEditState] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);

  const TABLE_HEAD = [
    {
      id: "district_name_en",
      label: "DISTRICT",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div
              onClick={() => handleClickTehsilList(row)}
              className=" d-flex align-items-center mb-0 pointer capitalized"
            >
              <span>
                {row.district_name_en}
                {row.district_name_ur && (
                  <span className="font-family-urdu">
                    ( {row.district_name_ur} )
                  </span>
                )}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "number_of_tehsils",
      label: "NO.TEHSIL",
      className: "typography-color-in-table",
    },
    {
      id: "number_of_farmers",
      label: "NO.FARMER",
      className: "typography-color-in-table",
    },
    {
      id: "number_of_companies",
      label: "NO.COMPANIES",
      className: "typography-color-in-table",
    },
    {
      id: "updatedAt",
      label: "Created At",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className=" d-flex align-items-center mb-0">
              <span className="capitalized">
                {moment(row.updatedAt).format("DD MMMM YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    {
      id: "action",
      label: "ACTION",
      type: "action",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_district_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleClickAddTehsil = (row) => {
    setTehsilModalState(true);
    setRowData(row);
  };

  const handleClickTehsilList = (row) => {
    setTehsilListModalState(true);
    setRowData(row);
  };

  const handleClickEdit = (row) => {
    setModalState(true);
    setRowData(row);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setModalState(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_district(deleteData?._id);
    if (result.code === 200) {
      const newList = districtList.filter(
        (target) => target._id !== deleteData?._id
      );
      setDistrictList(newList);
      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      district_ids: selectedIDS,
    };

    const result = await _delete_selected_district(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_district_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Add Tehsil",
      icon: "tabler:map-plus",
      handleClick: handleClickAddTehsil,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_district_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      status: data.status,
      district_id: data.district_id,
    };

    if (postData.status === "all") {
      delete postData.status;
    }
    if (postData.district_id === "all") {
      delete postData.district_id;
    }

    setIsLoading(true);
    const result = await _list_of_districts(page, rowsPerPage, postData);

    if (result.code === 200) {
      setDistrictList(result.list_of_districts);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    get_district_list();
  };

  useEffect(() => {
    get_district_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Districts");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <FilterDistricts
              filterInputs={filterInputs}
              handleFilterChange={handleFilterChange}
              onCloseDrawer={onCloseDrawer}
              handleResetFilter={handleResetFilter}
              handleFilterSubmit={searchFunction}
            />
          </>
        }
      />
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this District?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Districts?"}
        handleAgree={handleSelectedDelete}
      />

      <AddOrUpdateDistrict
        modalState={modalState}
        setModalState={setModalState}
        rowData={rowData}
        districtList={districtList}
        setDistrictList={setDistrictList}
        setTotalCount={setTotalCount}
        tehsilListModalState={tehsilListModalState}
        tehsilModalState={tehsilModalState}
      />

      <AddOrUpdateTehsil
        modalState={tehsilModalState}
        setModalState={setTehsilModalState}
        rowData={rowData}
        districtList={districtList}
        setDistrictList={setDistrictList}
      />

      <CustomPopover
        isOpenPop={tehsilListModalState}
        isClosePop={setTehsilListModalState}
        title={`${rowData?.district_name_en} Tehsils`}
        submitButtonText={"Save"}
        showStatus={false}
        showAllButtons={false}
        className={tehsilModalEditState ? "tehsil-list-popup-hide" : ""}
        width="710px"
        XSToMdScreen="90%"
        componentToPassDown={
          <>
            <TehsilList
              rowData={rowData}
              tehsilModalState={tehsilModalEditState}
              setTehsilModalState={setTehsilModalEditState}
              districtList={districtList}
              setDistrictList={setDistrictList}
            />
          </>
        }
      />

      <div className="mt-2">
        <div className="d-flex   justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={() => {
                handleAgreeSelectedDelete();
              }}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          {/* <Button
            variant="contained"
            startIcon={<Iconify    className="button-Iconify-in-listing" icon="hugeicons:filter" />}
            onClick={() => {
              onOpenDrawer();
            }}
            className="capitalized me-3"
          >
            Filter
          </Button> */}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              setRowData(null);
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New District
          </Button>
        </div>

        <CustomTable
          data={districtList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
    </>
  );
};

export default Districts;
