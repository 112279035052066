import React, { useState } from "react";
import CustomPopover from "../../components/CustomPopover";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import { useSnackbar } from "notistack";
import { _add_crop, _update_crop } from "../../DAL/Crops/crops";
import { useEffect } from "react";
import { imageBaseUrl } from "../../config/config";

const AddOrUpdateCrop = ({
  modalState,
  setModalState,
  rowData,
  cropList,
  setCropList,
  setTotalCount,
  get_crop_list,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  const [formInputs, setFormInputs] = useState({
    crop_name_en: "",
    crop_name_ur: "",
    order: "",
    season: "",
  });

  function handleFile(e) {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  }
  function handleRemoveImage() {
    setImage(null);
    setPreviewUrl("");
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const addNewCrop = (newCrop) => {
    setCropList((prevCropList) => [...prevCropList, newCrop]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setPreviewUrl("");
    setImage(null);
    setIsStatusActive(true);
    setFormInputs({
      crop_name_en: "",
      crop_name_ur: "",
      season: "",
      order: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formInputs.crop_name_en && !formInputs.crop_name_ur) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    if (!formInputs.season) {
      enqueueSnackbar("Season is required", { variant: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("crop_name_en", formInputs.crop_name_en);
    if (formInputs.crop_name_ur) {
      formData.append("crop_name_ur", formInputs.crop_name_ur);
    }
    formData.append("season", formInputs.season);
    formData.append("status", isStatusActive);

    if (image) {
      formData.append("image", image);
    }
    if (rowData?._id) {
      formData.append("order", formInputs.order);
    }
    setSubmitButtonLoader(true);

    const result = rowData?._id
      ? await _update_crop(rowData?._id, formData)
      : await _add_crop(formData);

    if (result.code === 200) {
      const newData = result.crop;

      setPreviewUrl(newData ? imageBaseUrl + newData?.image_url : "");

      if (rowData?._id) {
        get_crop_list();
        setModalState(false);
      } else {
        addNewCrop(newData);
        setModalState(false);
      }
      handleRefresh();
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
      setIsStatusActive(rowData?.status);
      setFormInputs({
        crop_name_en: rowData?.crop_name_en,
        crop_name_ur: rowData?.crop_name_ur,
        season: rowData?.season,
        order: rowData?.order,
      });
    }
  }, [rowData?._id, modalState]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);
  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowData?._id ? "Edit Crop" : "Add New Crop"}
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        handleSubmit={handleSubmit}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        submitButtonLoader={submitButtonLoader}
        componentToPassDown={
          <AddOrUpdateComponent
            formInputs={formInputs}
            previewUrl={previewUrl}
            handleFile={handleFile}
            handleChange={handleChange}
            handleRemoveImage={handleRemoveImage}
            rowData={rowData?._id}
          />
        }
      />
    </>
  );
};

export default AddOrUpdateCrop;
