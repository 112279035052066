import React from "react";
import { TextField, Avatar, IconButton, MenuItem } from "@mui/material";
import { PhotoCamera, Close } from "@mui/icons-material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";
import { useEffect } from "react";
import { useState } from "react";
import MultipleProductsSelectChip from "../../../components/MultipleProductsSelectChip";
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput";

const AddOrUpdateComponent = ({
  formInputs,
  setFormInputs,
  previewUrl,
  handleFile,
  handleChange,
  allDistrictList,
  handleRemoveImage,
  allServiceList,
  selectedServicies,
  setSelectedServicies,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [allTehsilList, setAllTehsilList] = useState([]);

  const handleDistrictChange = (e) => {
    const { target } = e;

    let matchedDistrict = allDistrictList.find(
      (district) => district._id === target.value
    );

    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      district: matchedDistrict,
      tehsil: null,
      tehsil_id: "",
    });

    setAllTehsilList(matchedDistrict?.tehsils ? matchedDistrict?.tehsils : []);
  };

  const handleTehsilChange = (e) => {
    const { target } = e;

    let matchedTehsil = allTehsilList.find(
      (tehsil) => tehsil._id === target.value
    );

    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      tehsil: matchedTehsil,
    });
  };

  useEffect(() => {
    let matchedDistrict = allDistrictList.find(
      (district) => district._id === formInputs.district_id
    );

    let tehsilsArray = [];
    if (matchedDistrict?.tehsils) {
      tehsilsArray = matchedDistrict?.tehsils.map((item) => {
        return {
          _id: item._id,
          tehsil_id: item._id,
          tehsil_name_en: item.tehsil_name_en,
          tehsil_name_ur: item.tehsil_name_ur,
        };
      });
    }
    setAllTehsilList(tehsilsArray);
  }, [formInputs.district_id, allDistrictList]);

  return (
    <>
      <div className="popover-mid-container">
        <div className="spacing">
          <div className="align-center">
            <div style={{ position: "relative" }}>
              <label htmlFor="image" style={{ cursor: "pointer" }}>
                {previewUrl ? (
                  <Avatar
                    src={previewUrl}
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  />
                ) : (
                  <Avatar
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  >
                    <PhotoCamera fontSize="large" />
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={handleFile}
                    />
                  </Avatar>
                )}
              </label>

              {previewUrl && (
                <IconButton
                  onClick={handleRemoveImage}
                  className="image-remove-icon"
                >
                  <Close />
                </IconButton>
              )}

              <IconButton component="label" htmlFor="image">
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  hidden
                  onChange={handleFile}
                />
              </IconButton>
            </div>
          </div>

          <p class="image-recommend-size">
            Recommended Size (670 X 1000) ( "png", "jpg", "jpeg", "webp","GIF" )
          </p>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Name"
              value={formInputs.service_provider_name_en}
              onChange={handleChange}
              name="service_provider_name_en"
              className="text-field-border"
              required
            />

            <CustomTextFieldUrdu
              label="* نام"
              value={formInputs.service_provider_name_ur}
              onChange={handleChange}
              name="service_provider_name_ur"
              labelDirection="left"
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="District"
              select
              value={formInputs?.district_id}
              onChange={handleDistrictChange}
              name="district_id"
              className="text-field-border capitalized"
              required
            >
              {allDistrictList &&
                allDistrictList.map((district) => (
                  <MenuItem
                    className="capitalized"
                    key={district._id}
                    value={district._id}
                  >
                    <span> {district.district_name_en} </span>
                    {district.district_name_ur && (
                      <span className="font-family-urdu">
                        ( {district.district_name_ur} )
                      </span>
                    )}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              fullWidth
              label="Tehsil"
              select
              value={formInputs?.tehsil_id}
              onChange={handleTehsilChange}
              name="tehsil_id"
              className="text-field-border capitalized"
              required
            >
              {allTehsilList &&
                allTehsilList.map((tehsil) => (
                  <MenuItem
                    className="capitalized"
                    key={tehsil._id}
                    value={tehsil._id}
                  >
                    <span> {tehsil.tehsil_name_en} </span>
                    {tehsil.tehsil_name_ur && (
                      <span className="font-family-urdu">
                        ( {tehsil.tehsil_name_ur} )
                      </span>
                    )}
                  </MenuItem>
                ))}
            </TextField>
          </div>

          <div className="row-spacing">
            <CustomPhoneInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              country="pk"
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allServiceList}
              selectedProducts={selectedServicies}
              setSelectedProducts={setSelectedServicies}
              nameId="_id"
              nameEn="service_name_en"
              nameUr="service_name_ur"
              label="Services *"
              placeholder="Add Service"
            />
          </div>

          <div className="row-spacing">
            <CustomTextFieldUrdu
              label="Further Details"
              value={formInputs.description}
              onChange={handleChange}
              name="description"
              labelDirection="left"
              direction=""
              multiline={true}
              rows={2}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
