import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Logo } from "../assets";

export default function LoginLayout() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{"FAO Login"}</title>
        <meta name="description" content={"FAO Login"} />
        <link rel="icon" href={Logo} />
      </Helmet>
      <Outlet />
    </>
  );
}
