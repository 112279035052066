import React, { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import CustomPopover from "../../components/CustomPopover";

import {
  _add_sales_market,
  _update_sales_market,
} from "../../DAL/SalesMarket/sales_market";
import { imageBaseUrl } from "../../config/config";

const emptyFormInputs = {
  buyer_name: "",
  district: null,
  district_id: "",
  tehsil: null,
  tehsil_id: "",
  description: "",
};

const AddOrUpdateBuyer = ({
  rowData,
  allCropList,
  selectedCropsName,
  setSelectedCropsName,
  salesMarketList,
  setSalesMarketList,
  modalState,
  setModalState,
  setTotalCount,
  get_all_district_crop_list,
  allDistrictList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  function handleFile(e) {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  }

  function handleRemoveImage() {
    setImage(null);
    setPreviewUrl("");
  }
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const updateListObject = (newItem) => {
    const newList = salesMarketList.map((item) => {
      if (item._id === rowData?._id) {
        return newItem;
      }
      return item;
    });
    setSalesMarketList(newList);
  };

  const addNewObject = (newItem) => {
    setSalesMarketList((prevItemList) => [newItem, ...prevItemList]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };
  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
    setPreviewUrl("");
    setPhoneNumber("");
    setImage(null);
    setSelectedCropsName([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formInputs.buyer_name) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    if (!formInputs.district_id) {
      enqueueSnackbar("District is required", { variant: "error" });
      return;
    }

    if (!formInputs.tehsil_id) {
      enqueueSnackbar("Tehsil is required", { variant: "error" });
      return;
    }

    if (!phoneNumber) {
      enqueueSnackbar("Phone Number is required", { variant: "error" });
      return;
    }

    if (selectedCropsName.length === 0) {
      enqueueSnackbar("Product is required", { variant: "error" });
      return;
    }

    let districtObject = {
      district_id: formInputs?.district?._id,
      district_name_en: formInputs?.district?.district_name_en,
      district_name_ur: formInputs?.district?.district_name_ur,
    };

    let tehsilObject = {
      tehsil_id: formInputs.tehsil?._id
        ? formInputs.tehsil?._id
        : formInputs.tehsil?.tehsil_id,
      tehsil_name_en: formInputs.tehsil?.tehsil_name_en,
      tehsil_name_ur: formInputs.tehsil?.tehsil_name_ur,
    };

    const formData = new FormData();
    formData.append("status", isStatusActive);
    formData.append("buyer_name", formInputs.buyer_name);
    formData.append("phone_number", phoneNumber);
    formData.append("description", formInputs.description);
    formData.append("district", JSON.stringify(districtObject));
    formData.append("tehsil", JSON.stringify(tehsilObject));
    formData.append("crops", JSON.stringify(selectedCropsName));

    if (image) {
      formData.append("image", image);
    }
    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_sales_market(rowData?._id, formData)
      : await _add_sales_market(formData);

    if (result.code === 200) {
      const newData = result.sales_market;

      if (rowData?._id) {
        updateListObject(newData);
      } else {
        addNewObject(newData);
      }
      handleRefresh();
      setModalState(false);
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      let district = rowData?.district;

      let newDistrictObj = {
        _id: district.district_id,
        district_name_en: district.district_name_en,
        district_name_ur: district.district_name_ur,
      };

      setIsStatusActive(rowData?.status);
      setFormInputs({
        ...formInputs,
        buyer_name: rowData?.buyer_name,
        description: rowData?.description,
        district: newDistrictObj,
        district_id: newDistrictObj._id,
        tehsil: rowData?.tehsil,
        tehsil_id: rowData?.tehsil.tehsil_id,
      });
      setPhoneNumber(rowData?.phone_number);
      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
    }

    if (modalState) {
      get_all_district_crop_list();
    }
  }, [rowData?._id, modalState, rowData]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowData?._id ? "Edit Buyer" : "Add New Buyer"}
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        handleSubmit={handleSubmit}
        submitButtonLoader={submitButtonLoader}
        width={"600px"}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              rowData={rowData}
              allCropList={allCropList}
              selectedCropsName={selectedCropsName}
              setSelectedCropsName={setSelectedCropsName}
              salesMarketList={salesMarketList}
              setSalesMarketList={setSalesMarketList}
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              previewUrl={previewUrl}
              handleFile={handleFile}
              handleChange={handleChange}
              allDistrictList={allDistrictList}
              handleRemoveImage={handleRemoveImage}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateBuyer;
