import { useEffect } from "react";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import CustomPopover from "../../components/CustomPopover";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import { _add_services, _update_services } from "../../DAL/Services/services";

const emptyFormInputs = {
  service_name_en: "",
  service_name_ur: "",
  order: "",
};

const AddOrUpdateService = ({
  modalState,
  setModalState,
  rowData,
  setServiceList,
  setTotalCount,
  get_service_list,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const addNewService = (newService) => {
    setServiceList((prevServiceList) => [...prevServiceList, newService]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formInputs.service_name_en && !formInputs.service_name_ur) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("service_name_en", formInputs.service_name_en);
    if (formInputs.service_name_ur) {
      formData.append("service_name_ur", formInputs.service_name_ur);
    }
    formData.append("status", isStatusActive);

    if (rowData?._id) {
      formData.append("order", formInputs.order);
    }
    setSubmitButtonLoader(true);

    const result = rowData?._id
      ? await _update_services(rowData?._id, formData)
      : await _add_services(formData);

    if (result.code === 200) {
      const newData = result.service;

      if (rowData?._id) {
        get_service_list();
        setModalState(false);
      } else {
        addNewService(newData);
        setModalState(false);
      }
      handleRefresh();
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      setIsStatusActive(rowData?.status);
      setFormInputs({
        service_name_en: rowData?.service_name_en,
        service_name_ur: rowData?.service_name_ur,
        order: rowData?.order,
      });
    }
  }, [rowData?._id, modalState]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);
  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowData?._id ? "Edit Service" : "Add New Service"}
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        handleSubmit={handleSubmit}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        submitButtonLoader={submitButtonLoader}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              formInputs={formInputs}
              handleChange={handleChange}
              rowData={rowData?._id}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateService;
