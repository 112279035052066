import { invokeApi } from "../invokeApi";

export const _list_of_services = async (page, rows, data) => {
  const requestObj = {
    path: `api/services/list_of_services?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_services = async (data) => {
  const requestObj = {
    path: `api/services/add_services`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_services = async (_id, data) => {
  const requestObj = {
    path: `api/services/update_services/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_service = async (_id) => {
  const requestObj = {
    path: `api/services/delete_services/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_services_by_id = async (id) => {
  const requestObj = {
    path: `api/services/get_services_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_services = async (data) => {
  const requestObj = {
    path: `api/services/delete_selected_services`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _all_list_of_services = async () => {
  const requestObj = {
    path: `api/services/all_list_of_services`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
