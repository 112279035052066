import React, { useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { display } from "@mui/system";

const AddMultiTehsilComponent = ({
  formInputs,
  additionalFields,
  setAdditionalFields,
}) => {
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);

  const handleRequiredCheck = (index) => {
    let object = additionalFields[index];

    if (object?.tehsil_name_en === "" && object?.tehsil_name_ur === "") {
      return true;
    }

    if (object?.tehsil_name_en !== "" && object?.tehsil_name_ur === "") {
      return false;
    }

    if (object?.tehsil_name_en === "" && object?.tehsil_name_ur !== "") {
      return false;
    }

    if (object?.tehsil_name_en !== "" && object?.tehsil_name_ur !== "") {
      return false;
    }

    return true;
  };

  const handleAddField = () => {
    setAdditionalFields([
      ...additionalFields,
      { tehsil_name_en: "", tehsil_name_ur: "" },
    ]);

    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  return (
    <>
      <div className="popover-mid-container">
        <h6 className="tehsil-label capitalized">
          {formInputs.district_name_en}{" "}
          {formInputs.district_name_ur && (
            <span className="font-family-urdu">
              ( {formInputs.district_name_ur} )
            </span>
          )}
        </h6>

        <div className="container">
          {additionalFields.map((field, index) => (
            <div className="row" key={index}>
              <div className="col-10 col-md-5 mb-3">
                <TextField
                  fullWidth
                  label={`Tehsil Name ${index + 1}`}
                  value={field.tehsil_name_en}
                  onChange={(e) => handleFieldChange(e, index)}
                  name="tehsil_name_en"
                  className="text-field-border"
                  required={handleRequiredCheck(index)}
                />
              </div>
              <div className="col-10 col-md-5 mb-3">
                <CustomTextFieldUrdu
                  fullWidth
                  label={`تحصیل کا نام ${index + 1}`}
                  value={field.tehsil_name_ur}
                  onChange={(e) => handleFieldChange(e, index)}
                  name="tehsil_name_ur"
                  placeholder={`تحصیل کا نام ${index + 1}`}
                  required={handleRequiredCheck(index)}
                />
              </div>

              <div class="col-2 col-md-2 add-Field-button d-flex  justify-content-center align-content-center align-items-center px-2">
                {showRemoveButtons[index] && (
                  <IconButton
                    aria-label="Delete Field"
                    onClick={() => handleDeleteField(index)}
                    color="error"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {additionalFields.length === index + 1 ? (
                  <IconButton
                    aria-label="Add Field"
                    onClick={handleAddField}
                    color="success"
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <div className="hide-plus-button-in-tehsil-add">+</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddMultiTehsilComponent;
