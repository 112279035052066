import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import Iconify from "../../../components/Iconify";

const FilterSalesMarket = ({
  filterInputs,
  setFilterInputs,
  handleFilterChange,
  handleResetFilter,
  onCloseDrawer,
  handleFilterSubmit,
  allDistrictList,
  allCropList,
  get_all_district_crop_list,
}) => {
  const [allTehsilList, setAllTehsilList] = useState([]);

  const handleDistrictChange = (e) => {
    const { target } = e;

    let matchedDistrict = allDistrictList.find(
      (district) => district._id === target.value
    );

    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
      district: matchedDistrict,
      tehsil: null,
      tehsil_id: "all",
    });

    setAllTehsilList(matchedDistrict?.tehsils ? matchedDistrict?.tehsils : []);
  };

  const handleTehsilChange = (e) => {
    const { target } = e;

    let matchedTehsil = allTehsilList.find(
      (tehsil) => tehsil._id === target.value
    );

    setFilterInputs({
      ...filterInputs,
      [target.name]: target.value,
      tehsil: matchedTehsil,
    });
  };

  useEffect(() => {
    let matchedDistrict = allDistrictList.find(
      (district) => district._id === filterInputs.district_id
    );

    let tehsilsArray = [];
    if (matchedDistrict?.tehsils) {
      tehsilsArray = matchedDistrict?.tehsils.map((item) => {
        return {
          _id: item._id,
          tehsil_id: item._id,
          tehsil_name_en: item.tehsil_name_en,
          tehsil_name_ur: item.tehsil_name_ur,
        };
      });
    }
    setAllTehsilList(tehsilsArray);

    get_all_district_crop_list();
  }, [filterInputs.district_id, allDistrictList]);

  useEffect(() => {
    get_all_district_crop_list();
  }, [filterInputs.crop_id]);

  return (
    <div className="container mt-4">
      <div className="spacing">
        <div className="row-spacing">
          <TextField
            fullWidth
            label="District"
            select
            value={filterInputs?.district_id}
            onChange={handleDistrictChange}
            name="district_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">None</MenuItem>
            {allDistrictList &&
              allDistrictList.map((district) => (
                <MenuItem
                  className="capitalized"
                  key={district._id}
                  value={district._id}
                >
                  <span> {district.district_name_en} </span>
                  {district.district_name_ur && (
                    <span className="font-family-urdu">
                      ( {district.district_name_ur} )
                    </span>
                  )}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="Tehsil"
            select
            value={filterInputs?.tehsil_id}
            onChange={handleTehsilChange}
            name="tehsil_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">None</MenuItem>
            {allTehsilList &&
              allTehsilList.map((tehsil) => (
                <MenuItem
                  className="capitalized"
                  key={tehsil._id}
                  value={tehsil._id}
                >
                  <span> {tehsil.tehsil_name_en} </span>
                  {tehsil.tehsil_name_ur && (
                    <span className="font-family-urdu">
                      ( {tehsil.tehsil_name_ur} )
                    </span>
                  )}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="Crop"
            select
            value={filterInputs?.crop_id}
            onChange={handleFilterChange}
            name="crop_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">None</MenuItem>
            {allCropList &&
              allCropList.map((crop) => (
                <MenuItem
                  className="capitalized"
                  key={crop.crop_id}
                  value={crop.crop_id}
                >
                  <span> {crop.crop_name_en} </span>
                  {crop.crop_name_ur && (
                    <span className="font-family-urdu">
                      ( {crop.crop_name_ur} )
                    </span>
                  )}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="Status"
            select
            value={filterInputs.status}
            onChange={handleFilterChange}
            name="status"
            className="text-field-border"
          >
            <MenuItem value="all">None</MenuItem>
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              onCloseDrawer();
              handleFilterSubmit(e);
            }}
          >
            <Iconify
              icon="material-symbols:filter-alt"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Filter
          </button>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              handleResetFilter();
              onCloseDrawer();
            }}
          >
            <Iconify
              icon="material-symbols:clear-all-rounded"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSalesMarket;
