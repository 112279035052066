import React from "react";
import { TextField, Avatar, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import MultipleProductsSelectChip from "../../../components/MultipleProductsSelectChip";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput";

const AddOrUpdateComponent = ({
  formInputs,
  previewUrl,
  handleFile,
  handleChange,
  handleRemoveImage,
  allTehsilList,
  selectedCities,
  setSelectedCities,
  allServiceList,
  selectedServicies,
  setSelectedServicies,
  phoneNumber,
  setPhoneNumber,
}) => {
  return (
    <>
      <div className="popover-mid-container">
        <div className="spacing">
          <div className="align-center">
            <div style={{ position: "relative" }}>
              <label htmlFor="image" style={{ cursor: "pointer" }}>
                {previewUrl ? (
                  <Avatar
                    src={previewUrl}
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  />
                ) : (
                  <Avatar
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  >
                    <PhotoCamera fontSize="large" />
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={handleFile}
                    />
                  </Avatar>
                )}
              </label>

              {previewUrl && (
                <IconButton
                  onClick={handleRemoveImage}
                  className="image-remove-icon"
                >
                  <span class="pointer_cursor" aria-label="Delete image">
                    X
                  </span>
                </IconButton>
              )}

              <IconButton component="label" htmlFor="image">
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  hidden
                  onChange={handleFile}
                />
              </IconButton>
            </div>
          </div>
          <p class="image-recommend-size">
            Recommended Size (670 X 1000) ( "png", "jpg", "jpeg", "webp","GIF" )
          </p>

          <div className="row-spacing">
            <CustomTextFieldUrdu
              label="Name"
              value={formInputs.consultancy_name_en}
              onChange={handleChange}
              name="consultancy_name_en"
              labelDirection="left"
              direction=""
              required
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Email"
              value={formInputs.email}
              onChange={handleChange}
              name="email"
              className="text-field-border"
              type="email"
            />
            <CustomPhoneInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              country="pk"
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allServiceList}
              selectedProducts={selectedServicies}
              setSelectedProducts={setSelectedServicies}
              nameId="_id"
              nameEn="service_name_en"
              nameUr="service_name_ur"
              label="Services *"
              placeholder="Add Service"
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allTehsilList}
              selectedProducts={selectedCities}
              setSelectedProducts={setSelectedCities}
              nameId="_id"
              nameEn="tehsil_name_en"
              nameUr="tehsil_name_ur"
              label="Cities *"
              placeholder="Add City"
            />
          </div>

          <div className="row-spacing">
            <CustomTextFieldUrdu
              label="Further Details"
              value={formInputs.description}
              onChange={handleChange}
              name="description"
              labelDirection="left"
              direction=""
              multiline={true}
              rows={2}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
