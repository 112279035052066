import React, { useEffect } from "react";
import { useState } from "react";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import CustomPopover from "../../components/CustomPopover";
import { imageBaseUrl } from "../../config/config";
import {
  _add_category,
  _update_category,
} from "../../DAL/Categories/categories";
import { useSnackbar } from "notistack";

const AddOrUpdateCategory = ({
  modalState,
  setModalState,
  rowData,
  categoryList,
  setCategoryList,
  setTotalCount,
  parentCategoryList,
  get_parent_category_list,
  get_category_list,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [previewUrl, setPreviewUrl] = useState("");
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  const [formInputs, setFormInputs] = useState({
    category_name_en: "",
    category_name_ur: "",
    parent_category: null,
    parent_category_id: "none",
    order: "",
  });

  function handleFile(e) {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  }

  function handleRemoveImage() {
    setImage(null);
    setPreviewUrl("");
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const addNewObject = (newItem) => {
    setCategoryList((prevItemList) => [...prevItemList, newItem]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setPreviewUrl("");
    setImage(null);
    setIsStatusActive(true);
    setFormInputs({
      category_name_en: "",
      category_name_ur: "",
      parent_category: null,
      parent_category_id: "none",
      order: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formInputs.category_name_en && !formInputs.category_name_ur) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }
    if (!formInputs.parent_category_id) {
      enqueueSnackbar("Parent Category is required", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("category_name_en", formInputs.category_name_en);

    if (formInputs.category_name_ur) {
      formData.append("category_name_ur", formInputs.category_name_ur);
    }
    if (
      formInputs.parent_category_id &&
      formInputs.parent_category_id != "none"
    ) {
      formData.append("parent_category_id", formInputs.parent_category_id);
    }

    formData.append("status", isStatusActive);

    if (image) {
      formData.append("image", image);
    }

    if (rowData?._id) {
      formData.append("order", formInputs.order);
    }

    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_category(rowData?._id, formData)
      : await _add_category(formData);

    if (result.code === 200) {
      const newData = result.category;
      setPreviewUrl(newData ? imageBaseUrl + newData?.image?.thumbnail_2 : "");

      if (rowData?._id) {
        get_category_list();
        setModalState(false);
      } else {
        addNewObject(newData);
        setModalState(false);
      }
      handleRefresh();
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      let parent_category_id = "none";
      let parent_category = null;

      if (rowData?.parent_category) {
        parent_category = rowData?.parent_category;
        parent_category_id = rowData?.parent_category?.parent_category_id;
      }
      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
      setIsStatusActive(rowData?.status);
      setFormInputs({
        category_name_en: rowData?.category_name_en,
        category_name_ur: rowData?.category_name_ur,
        parent_category: parent_category,
        parent_category_id: parent_category_id,
        order: rowData?.order,
      });
    }

    if (modalState) {
      get_parent_category_list();
    }
  }, [rowData?._id, modalState]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowData?._id ? "Edit Category" : "Add New Category"}
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        handleSubmit={handleSubmit}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        submitButtonLoader={submitButtonLoader}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              formInputs={formInputs}
              previewUrl={previewUrl}
              handleFile={handleFile}
              handleChange={handleChange}
              parentCategoryList={parentCategoryList}
              rowDataID={rowData?._id}
              handleRemoveImage={handleRemoveImage}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateCategory;
