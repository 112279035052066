import { invokeApi } from "../invokeApi";

export const _list_of_knowledge_bank_entries = async (page, rows, data) => {
  const requestObj = {
    path: `api/knowledge_bank/list_of_knowledge_bank_entries?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_knowledge_bank_entry = async (data) => {
  const requestObj = {
    path: `api/knowledge_bank/add_knowledge_bank_entry`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_knowledge_bank_entry = async (_id, data) => {
  const requestObj = {
    path: `api/knowledge_bank/update_knowledge_bank_entry/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_knowledge_bank_entry = async (_id) => {
  const requestObj = {
    path: `api/knowledge_bank/delete_knowledge_bank_entry/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_knowledge_bank_entry_by_id = async (id) => {
  const requestObj = {
    path: `api/knowledge_bank/get_knowledge_bank_entry_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_knowledge_bank_entries = async (data) => {
  const requestObj = {
    path: `api/knowledge_bank/delete_selected_knowledge_bank_entries`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
