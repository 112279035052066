import moment from "moment";
import { useSnackbar } from "notistack";
import { STATUS } from "../../utils/constant";
import { Avatar, Button } from "@mui/material";
import Iconify from "../../components/Iconify";
import React, { useEffect, useState } from "react";
import { imageBaseUrl } from "../../config/config";
import AddOrUpdateCompany from "./AddOrUpdateCompany";
import CustomDrawer from "../../components/CustomDrawer";
import FilterCompanies from "./components/FilterCompanies";
import { useAdminContext } from "../../Hooks/AdminContext";
import CustomTable from "../../components/customTable/CustomTable";
import { _all_list_of_tehsil } from "../../DAL/Districts/districts";
import CircularLoader from "../../components/loaders/CircularLoader";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { _all_list_of_category } from "../../DAL/Categories/categories";
import {
  _delete_companies,
  _delete_selected_companies,
  _list_of_companies,
} from "../../DAL/Companies/companies";
import MultipleItemsShowList from "../../components/MultipleItemsShowList";
import CustomImageAvatar from "../../components/CustomImageAvatar";

const emptyFilter = {
  status: "all",
  category_id: "all",
  city_id: "all",
};

const Companies = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setnavbarTitle } = useAdminContext();
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [deleteData, setDeleteData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [modalState, setModalState] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [allTehsilList, setAllTehsilList] = useState([]);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [showMultiItems, setShowMultiItems] = useState(false);
  const [showMultiItemsType, setShowMultiItemsType] = useState("city");
  const [showMultiItemsData, setShowMultiItemsData] = useState(null);

  const TABLE_HEAD = [
    {
      id: "company_name",
      label: "NAME",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center mb-0">
              <CustomImageAvatar
                imageUrl={row?.image?.thumbnail_2}
                altText={row.company_name}
              />
              <span className="capitalized">{row.company_name}</span>
            </div>
          </>
        );
      },
    },
    {
      id: "email",
      label: "E-MAIL",
      className: "typography-color-in-table",
    },
    {
      id: "phone_number",
      label: "PHONE NUMBER",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <span>+</span>
            {row?.phone_number}
          </>
        );
      },
    },
    {
      id: "category",
      label: "CATEGORY",
      renderData: (row) => {
        const items = row.category;
        let singleName = items[0]?.category_name_en;
        if (items[0]?.category_name_ur) {
          singleName = items[0]?.category_name_ur;
        }
        const additionalCount = items.length > 1 ? ` +${items.length - 1}` : "";
        return (
          <div className=" d-flex align-items-center mb-0 sales-market-count">
            <span className="sales-market-chip font-family-urdu">
              {singleName}
            </span>
            <span
              className="pointer"
              onClick={() => multipleItemsShowList(items, "category")}
            >
              {additionalCount}
            </span>
          </div>
        );
      },
    },
    {
      id: "city",
      label: "CITY",
      renderData: (row) => {
        const items = row.city;
        let singleName = items[0]?.tehsil_name_en;
        if (items[0]?.tehsil_name_ur) {
          singleName = items[0]?.tehsil_name_ur;
        }
        const additionalCount = items.length > 1 ? ` +${items.length - 1}` : "";
        return (
          <div className=" d-flex align-items-center mb-0 sales-market-count">
            <span className="sales-market-chip font-family-urdu">
              {singleName}
            </span>
            <span
              className="pointer"
              onClick={() => multipleItemsShowList(items, "city")}
            >
              {additionalCount}
            </span>
          </div>
        );
      },
    },
    {
      id: "joining_date",
      label: "JOINING DATE",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className=" d-flex align-items-center mb-0">
              <span className="capitalized">
                {moment(row.joining_date).format("DD MMMM YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "website_url",
      label: "WEBSITE URL",
      renderData: (row) => {
        return (
          <>
            <div className=" d-flex align-items-center mb-0">
              <a
                href={row.website_url}
                target="_blank"
                rel="noopener noreferrer"
                className="company-url-preview"
              >
                Preview
              </a>
            </div>
          </>
        );
      },
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    { id: "action", label: "ACTION", type: "action" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_company_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const multipleItemsShowList = (row, type) => {
    setShowMultiItemsType(type);
    setShowMultiItems(true);
    setShowMultiItemsData(row);
  };

  const handleClickEdit = (row) => {
    setModalState(true);
    setRowData(row);
    setSelectedCategories(row?.category);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setRowData(null);
    setModalState(true);
    setSelectedCategories([]);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_companies(deleteData?._id);
    if (result.code === 200) {
      const newList = companyList.filter(
        (target) => target._id !== deleteData?._id
      );

      setCompanyList(newList);
      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      company_ids: selectedIDS,
    };

    const result = await _delete_selected_companies(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_company_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_all_category_list = async () => {
    if (allCategoryList.length > 0) {
      return;
    }

    const result = await _all_list_of_category();
    if (result.code === 200) {
      setAllCategoryList(result.list_of_category);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_all_tehsil_list = async () => {
    if (allTehsilList.length > 0) {
      return;
    }

    const result = await _all_list_of_tehsil();
    if (result.code === 200) {
      setAllTehsilList(result.list_of_tehsils);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_company_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      status: data.status,
      category_id: data.category_id,
      city_id: data.city_id,
    };

    if (postData.status === "all") {
      delete postData.status;
    }

    if (postData.category_id === "all") {
      delete postData.category_id;
    }

    if (postData.city_id === "all") {
      delete postData.city_id;
    }

    setIsLoading(true);
    const result = await _list_of_companies(page, rowsPerPage, postData);

    if (result.code === 200) {
      setCompanyList(result.list_of_companies);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    get_company_list();
  };

  useEffect(() => {
    get_company_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Companies");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <MultipleItemsShowList
        dataList={showMultiItemsData ? showMultiItemsData : []}
        modalState={showMultiItems}
        setModalState={setShowMultiItems}
        title={showMultiItemsType === "city" ? "All Cities" : "All Categories"}
        nameEn={
          showMultiItemsType === "city" ? "tehsil_name_en" : "category_name_en"
        }
        nameUr={
          showMultiItemsType === "city" ? "tehsil_name_ur" : "category_name_ur"
        }
      />

      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <FilterCompanies
              filterInputs={filterInputs}
              onCloseDrawer={onCloseDrawer}
              allTehsilList={allTehsilList}
              allCategoryList={allCategoryList}
              setFilterInputs={setFilterInputs}
              handleFilterSubmit={searchFunction}
              handleResetFilter={handleResetFilter}
              handleFilterChange={handleFilterChange}
              get_all_tehsil_list={get_all_tehsil_list}
              get_all_category_list={get_all_category_list}
            />
          </>
        }
      />

      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Company?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Companies?"}
        handleAgree={handleSelectedDelete}
      />

      <AddOrUpdateCompany
        rowData={rowData}
        setTotalCount={setTotalCount}
        modalState={modalState}
        setModalState={setModalState}
        companyList={companyList}
        setCompanyList={setCompanyList}
        allTehsilList={allTehsilList}
        allCategoryList={allCategoryList}
        selectedCities={selectedCities}
        setSelectedCities={setSelectedCities}
        get_all_tehsil_list={get_all_tehsil_list}
        get_all_category_list={get_all_category_list}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />

      <div className="mt-2">
        <div className="d-flex   justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={() => {
                handleAgreeSelectedDelete();
              }}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="hugeicons:filter"
              />
            }
            onClick={() => {
              onOpenDrawer();
            }}
            className="capitalized me-3 button-in-listing"
          >
            Filter
          </Button>

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New Company
          </Button>
        </div>

        <CustomTable
          data={companyList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
    </>
  );
};

export default Companies;
