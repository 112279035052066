import React, { useEffect } from "react";
import { useState } from "react";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import CustomPopover from "../../components/CustomPopover";
import { imageBaseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import {
  _add_knowledge_bank_entry,
  _update_knowledge_bank_entry,
} from "../../DAL/KnowledgeBank/knowledge_bank";

const emptyFormInputs = {
  title: "",
  content_type: "other",
  other_content: "",
  description: "",
  order: "",
};

const AddOrUpdateKnowledgeBank = ({
  modalState,
  setModalState,
  rowData,
  setTotalCount,
  knowledgeBankList,
  setKnowledgeBankList,
  get_knowledge_bank_list,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  function handleFile(e) {
    const file = e.target.files[0];
    setFile(file);
    if (formInputs.content_type === "audio") {
      setAudioUrl(URL.createObjectURL(file));
    } else {
      setPreviewUrl(URL.createObjectURL(file));
    }
  }

  function handleRemoveImage() {
    setFile(null);
    setPreviewUrl("");
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const addNewObject = (newItem) => {
    setKnowledgeBankList((prevItemList) => [...prevItemList, newItem]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
    setPreviewUrl("");
    setAudioUrl("");
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formInputs.title) {
      enqueueSnackbar("Title is required", { variant: "error" });
      return;
    }
    if (
      formInputs.content_type !== "audio" &&
      formInputs.content_type !== "image" &&
      formInputs.content_type !== "other"
    ) {
      enqueueSnackbar("Content Type is required", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("status", isStatusActive);
    formData.append("title", formInputs.title);
    formData.append("content_type", formInputs.content_type);
    formData.append("description", formInputs.description);

    if (rowData?._id) {
      formData.append("order", formInputs.order);
    }

    if (formInputs.content_type === "other") {
      formData.append("other_content", formInputs.other_content);
    }

    if (file && formInputs.content_type !== "other") {
      if (formInputs.content_type === "audio") {
        formData.append("audio", file);
      } else {
        formData.append("image", file);
      }
    }

    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_knowledge_bank_entry(rowData?._id, formData)
      : await _add_knowledge_bank_entry(formData);

    if (result.code === 200) {
      const newData = result.knowledge_bank;

      if (rowData?._id) {
        get_knowledge_bank_list();
      } else {
        addNewObject(newData);
      }
      handleRefresh();
      setModalState(false);
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      setIsStatusActive(rowData?.status);
      setFormInputs({
        ...formInputs,
        title: rowData?.title,
        content_type: rowData?.content_type,
        other_content: rowData?.other_content,
        description: rowData?.description,
        order: rowData?.order,
      });

      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
      setAudioUrl(rowData?.audio_url ? imageBaseUrl + rowData?.audio_url : "");
    }
  }, [rowData?._id, modalState, rowData]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowData?._id ? "Edit Knowledge Bank" : "Add New Knowledge Bank"}
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        handleSubmit={handleSubmit}
        submitButtonLoader={submitButtonLoader}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              audioUrl={audioUrl}
              setAudioUrl={setAudioUrl}
              handleFile={handleFile}
              handleChange={handleChange}
              handleRemoveImage={handleRemoveImage}
              rowData={rowData}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateKnowledgeBank;
