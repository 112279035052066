import React, { useEffect } from "react";
import { useState } from "react";
import { TextField, Avatar, IconButton, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { Close, PhotoCamera } from "@mui/icons-material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";

const AddOrUpdateComponent = ({
  formInputs,
  previewUrl,
  handleFile,
  handleChange,
  handleRemoveImage,
  rowData,
}) => {
  const [nameEnglishRequired, setNameEnglishRequired] = useState(true);
  const [nameUrduRequired, setNameUrduRequired] = useState(true);

  useEffect(() => {
    if (!formInputs.crop_name_en && !formInputs.crop_name_ur) {
      setNameEnglishRequired(true);
      setNameUrduRequired(true);
    } else if (!formInputs.crop_name_en) {
      setNameEnglishRequired(false);
      setNameUrduRequired(true);
    } else if (!formInputs.crop_name_ur) {
      setNameEnglishRequired(true);
      setNameUrduRequired(false);
    }
  }, [formInputs]);
  console.log(rowData, "rowDatarowData");
  return (
    <div className="popover-mid-container">
      <div className="spacing">
        <div className="align-center">
          <div style={{ position: "relative" }}>
            <label htmlFor="image" style={{ cursor: "pointer" }}>
              {previewUrl ? (
                <Avatar
                  src={previewUrl}
                  sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                  variant="square"
                  className="table-image-with-name"
                />
              ) : (
                <Avatar
                  sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                  variant="square"
                  className="table-image-with-name"
                >
                  <PhotoCamera fontSize="large" />
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    hidden
                    onChange={handleFile}
                  />
                </Avatar>
              )}
            </label>

            {previewUrl && (
              <IconButton
                onClick={handleRemoveImage}
                className="image-remove-icon"
              >
                <Close />
              </IconButton>
            )}

            <IconButton component="label" htmlFor="image">
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                hidden
                onChange={handleFile}
              />
            </IconButton>
          </div>
        </div>
        <p class="image-recommend-size">
          Recommended Size (670 X 1000) ( "png", "jpg", "jpeg", "webp","GIF" )
        </p>
        <div className="row-spacing">
          <TextField
            fullWidth
            label="Name of crop"
            value={formInputs.crop_name_en}
            onChange={handleChange}
            name="crop_name_en"
            className="text-field-border"
            required={nameEnglishRequired}
          />

          <CustomTextFieldUrdu
            label="فصل کا نام"
            value={formInputs.crop_name_ur}
            onChange={handleChange}
            name="crop_name_ur"
            placeholder="فصل کا نام"
            required={nameUrduRequired}
          />
        </div>
        <div className="row-spacing">
          <TextField
            fullWidth
            label="Season"
            select
            value={formInputs.season}
            onChange={handleChange}
            name="season"
            className="text-field-border"
            required
          >
            <MenuItem value="spring">Spring</MenuItem>
            <MenuItem value="summer">Summer</MenuItem>
            <MenuItem value="fall">Fall</MenuItem>
            <MenuItem value="winter">Winter</MenuItem>
            <MenuItem value="autumn">Autumn</MenuItem>
          </TextField>
          {rowData && (
            <TextField
              fullWidth
              label="Order"
              value={formInputs.order}
              onChange={handleChange}
              name="order"
              type="number"
              className="text-field-border"
              required
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOrUpdateComponent;
