import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { STATUS, truncateString } from "../../utils/constant";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Avatar, Button, Tooltip } from "@mui/material";
import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AddOrUpdateKnowledgeBank from "./AddOrUpdateKnowledgeBank";
import { useSnackbar } from "notistack";
import {
  _delete_knowledge_bank_entry,
  _delete_selected_knowledge_bank_entries,
  _list_of_knowledge_bank_entries,
} from "../../DAL/KnowledgeBank/knowledge_bank";
import CircularLoader from "../../components/loaders/CircularLoader";
import { imageBaseUrl } from "../../config/config";
import CustomDrawer from "../../components/CustomDrawer";
import FilterKnowledgeBank from "./components/FilterKnowledgeBank";

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

const emptyFilter = {
  status: "all",
  content_type: "all",
};

const KnowledgeBank = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setnavbarTitle } = useAdminContext();
  const [knowledgeBankList, setKnowledgeBankList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);

  const CustomTooltipTitle = ({ title, _dangerouslySetInnerHTML }) => {
    return _dangerouslySetInnerHTML ? (
      <span
        className="font-family-urdu"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    ) : (
      <span className="font-family-urdu">{title}</span>
    );
  };

  const openLinkInNewTab = (row) => {
    let url = row.other_content;
    if (row.content_type == "audio") {
      url = imageBaseUrl + row.audio_url;
    } else if (row.content_type == "image") {
      url = imageBaseUrl + row?.image?.thumbnail_1;
    }

    window.open(url, "_blank");
  };

  const TABLE_HEAD = [
    {
      id: "content_type",
      label: "MEDIA TYPE",
      renderData: (row) => {
        let typeIcon = "mdi:text";
        if (row.content_type === "other") {
          typeIcon = "mynaui:video";
        }
        if (row.content_type === "audio") {
          typeIcon = "mynaui:music";
        }
        return (
          <>
            <div className=" d-flex align-items-center mb-0">
              <Avatar
                variant="square"
                className="knowledge-bank-type-avarar"
                sx={{ bgcolor: "blue" }}
              >
                {getIcon(typeIcon)}
              </Avatar>
            </div>
          </>
        );
      },
    },

    {
      id: "title",
      label: "TITLE",
      className: "typography-color-in-table",
      renderData: (row) => {
        let title = "";
        let titleLength = 0;
        if (row?.title) {
          titleLength = row.title.length;
          title = row.title;
        }
        return (
          <div className="d-flex align-items-center mb-0">
            <span className="font-family-urdu">
              {title ? (
                <Tooltip
                  title={
                    titleLength > 35 ? (
                      <CustomTooltipTitle
                        title={title}
                        dangerouslySetInnerHTML={false}
                      />
                    ) : (
                      ""
                    )
                  }
                  arrow
                  placement="top"
                  className="font-family-urdu"
                >
                  <span
                    className="pointer capitalized"
                    style={{ fontWeight: 500 }}
                  >
                    {truncateString(title, 35)}
                  </span>
                </Tooltip>
              ) : (
                <span>N/A</span>
              )}
            </span>
          </div>
        );
      },
    },
    // {
    //   id: "description",
    //   label: "DESCRIPTION",
    //   renderData: (row) => {
    //     let description = "";
    //     let descriptionLength = 0;
    //     if (row?.description) {
    //       descriptionLength = row.description.length;
    //       description = row.description;
    //     }

    //     return (
    //       <div className=" d-flex align-items-center mb-0">
    //         <span className="font-family-urdu">
    //           {description ? (
    //             <Tooltip
    //               title={
    //                 descriptionLength > 35 ? (
    //                   <CustomTooltipTitle
    //                     title={description}
    //                     dangerouslySetInnerHTML={true}
    //                   />
    //                 ) : (
    //                   ""
    //                 )
    //               }
    //               arrow
    //               placement="top"
    //               className="font-family-urdu"
    //             >
    //               <span
    //                 style={{ fontWeight: 500 }}
    //                 className="pointer capitalized knowledge-bank-discription font-family-urdu "
    //                 dangerouslySetInnerHTML={{
    //                   __html: truncateString(description, 35),
    //                 }}
    //               />
    //             </Tooltip>
    //           ) : (
    //             <span>N/A</span>
    //           )}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      id: "media_image",
      label: "MEDIA",

      renderData: (row) => {
        let typeIcon = "mdi:text";
        if (row.content_type === "other") {
          typeIcon = "mynaui:video";
        }
        if (row.content_type === "audio") {
          typeIcon = "mynaui:music";
        }

        return (
          <>
            <div
              onClick={(e) => openLinkInNewTab(row)}
              className=" d-flex align-items-center mb-0"
            >
              {row.content_type === "image" ? (
                <Avatar
                  src={imageBaseUrl + row?.image?.thumbnail_2}
                  variant="square"
                  className="table-image-with-name knowledge-bank-table-image pointer"
                />
              ) : (
                <Avatar
                  variant="square"
                  className="table-image-with-name knowledge-bank-table-image pointer"
                >
                  {getIcon(typeIcon)}
                </Avatar>
              )}
            </div>
          </>
        );
      },
    },
    {
      id: "updatedAt",
      label: "Created At",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center mb-0">
              <span className="capitalized">
                {moment(row.updatedAt).format("DD MMMM YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "order",
      label: "ORDER",
      className: "typography-color-in-table",
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    { id: "action", label: "ACTION", type: "action" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_knowledge_bank_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleClickEdit = (row) => {
    setModalState(true);
    setRowData(row);
    setRowData(row);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setRowData(null);
    setModalState(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_knowledge_bank_entry(deleteData?._id);
    if (result.code === 200) {
      const newList = knowledgeBankList.filter(
        (target) => target._id !== deleteData?._id
      );
      const updatedList = newList.map((item, index) => ({
        ...item,
        order: index + 1,
      }));

      setKnowledgeBankList(updatedList);

      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      knowledge_bank_ids: selectedIDS,
    };

    const result = await _delete_selected_knowledge_bank_entries(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_knowledge_bank_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_knowledge_bank_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      status: data.status,
      content_type: data.content_type,
    };

    console.log("postData   __check  _before", postData);
    if (postData.status === "all") {
      delete postData.status;
    }

    if (postData.content_type === "all") {
      delete postData.content_type;
    }

    console.log("postData   __check  _after", postData);

    setIsLoading(true);
    const result = await _list_of_knowledge_bank_entries(
      page,
      rowsPerPage,
      postData
    );
    console.log("result   _list_of_knowledge_bank_entries  __test", result);

    if (result.code === 200) {
      setKnowledgeBankList(result.knowledge_bank);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    get_knowledge_bank_list();
  };

  useEffect(() => {
    get_knowledge_bank_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Knowledge Bank");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <FilterKnowledgeBank
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              handleFilterChange={handleFilterChange}
              onCloseDrawer={onCloseDrawer}
              handleResetFilter={handleResetFilter}
              handleFilterSubmit={searchFunction}
            />
          </>
        }
      />

      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Knowledge Bank?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Knowledge Bank?"}
        handleAgree={handleSelectedDelete}
      />
      <AddOrUpdateKnowledgeBank
        modalState={modalState}
        setModalState={setModalState}
        rowData={rowData}
        setTotalCount={setTotalCount}
        knowledgeBankList={knowledgeBankList}
        setKnowledgeBankList={setKnowledgeBankList}
        get_knowledge_bank_list={get_knowledge_bank_list}
      />

      <div className="mt-2">
        <div className="d-flex   justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={() => {
                handleAgreeSelectedDelete();
              }}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="hugeicons:filter"
              />
            }
            onClick={() => {
              onOpenDrawer();
            }}
            className="capitalized me-3 button-in-listing"
          >
            Filters
          </Button>

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              setRowData(null);
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New Knowledge Bank
          </Button>
        </div>

        <CustomTable
          data={knowledgeBankList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
    </>
  );
};

export default KnowledgeBank;
