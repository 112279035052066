import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import Iconify from "../../../components/Iconify";

const FilterCompanies = ({
  filterInputs,
  setFilterInputs,
  handleFilterChange,
  handleResetFilter,
  onCloseDrawer,
  handleFilterSubmit,
  allTehsilList,
  allCategoryList,
  get_all_tehsil_list,
  get_all_category_list,
}) => {
  useEffect(() => {
    get_all_tehsil_list();
    get_all_category_list();
  }, [filterInputs.category_id, filterInputs.city_id]);

  return (
    <div className="container mt-4">
      <div className="spacing">
        <div className="row-spacing">
          <TextField
            fullWidth
            label="Category"
            select
            value={filterInputs?.category_id}
            onChange={handleFilterChange}
            name="category_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">None</MenuItem>
            {allCategoryList &&
              allCategoryList.map((category) => (
                <MenuItem
                  className="capitalized"
                  key={category._id}
                  value={category._id}
                >
                  <span> {category.category_name_en} </span>
                  {category.category_name_ur && (
                    <span className="font-family-urdu">
                      ( {category.category_name_ur} )
                    </span>
                  )}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="City"
            select
            value={filterInputs?.city_id}
            onChange={handleFilterChange}
            name="city_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">None</MenuItem>
            {allTehsilList &&
              allTehsilList.map((tehsil) => (
                <MenuItem
                  className="capitalized"
                  key={tehsil._id}
                  value={tehsil._id}
                >
                  <span> {tehsil.tehsil_name_en} </span>
                  {tehsil.tehsil_name_ur && (
                    <span className="font-family-urdu">
                      ( {tehsil.tehsil_name_ur} )
                    </span>
                  )}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="Status"
            select
            value={filterInputs.status}
            onChange={handleFilterChange}
            name="status"
            className="text-field-border"
          >
            <MenuItem value="all">None</MenuItem>
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              onCloseDrawer();
              handleFilterSubmit(e);
            }}
          >
            <Iconify
              icon="material-symbols:filter-alt"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Filter
          </button>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              handleResetFilter();
              onCloseDrawer();
            }}
          >
            <Iconify
              icon="material-symbols:clear-all-rounded"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterCompanies;
