import { invokeApi } from "../invokeApi";

export const _list_of_crops = async (page, rows, data) => {
  const requestObj = {
    path: `api/crop/list_of_crops?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_crop = async (data) => {
  const requestObj = {
    path: `api/crop/add_crop`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_crop = async (_id, data) => {
  const requestObj = {
    path: `api/crop/update_crop/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_crop = async (_id) => {
  const requestObj = {
    path: `api/crop/delete_crop/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_detail_of_crop_by_id = async (id) => {
  const requestObj = {
    path: `api/crop/get_crop_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_crop = async (data) => {
  const requestObj = {
    path: `api/crop/delete_selected_crop`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _all_list_of_crop = async () => {
  const requestObj = {
    path: `api/crop/all_list_of_crop`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
