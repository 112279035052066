import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { STATUS } from "../../utils/constant";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Button } from "@mui/material";
import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AddOrUpdateCrop from "./AddOrUpdateCrop";
import {
  _delete_crop,
  _delete_selected_crop,
  _list_of_crops,
} from "../../DAL/Crops/crops";
import { useSnackbar } from "notistack";
import CircularLoader from "../../components/loaders/CircularLoader";
import CustomDrawer from "../../components/CustomDrawer";
import FilterCrops from "./components/FilterCrops";
import CustomImageAvatar from "../../components/CustomImageAvatar";

const emptyFilter = {
  status: "all",
  season: "all",
};

const Crops = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setnavbarTitle } = useAdminContext();
  const [cropList, setCropList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [delLoading, setDelLoading] = useState(false);
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);

  const TABLE_HEAD = [
    {
      id: "crop_name_en",
      label: "CROP",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <div className=" d-flex align-items-center mb-0">
            <CustomImageAvatar
              imageUrl={row?.image?.thumbnail_2}
              altText={row.crop_name_en}
            />
            <span className="capitalized">
              {row.crop_name_en}{" "}
              {row.crop_name_ur && (
                <span className="font-family-urdu">( {row.crop_name_ur} )</span>
              )}
            </span>
          </div>
        );
      },
    },
    {
      id: "season",
      label: "SEASON",
      className: "capitalized typography-color-in-table",
    },
    {
      id: "updatedAt",
      label: "Created At",
      className: "typography-color-in-table",
      renderData: (row) => (
        <div className=" d-flex align-items-center mb-0">
          <span className="capitalized">
            {moment(row.updatedAt).format("DD MMMM YYYY")}
          </span>
        </div>
      ),
    },
    {
      id: "order",
      label: "Order",
      className: "typography-color-in-table",
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <span className={`${find_status?.class}`}>{find_status?.name}</span>
        );
      },
    },
    { id: "action", label: "ACTION", type: "action" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_crop_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleClickEdit = (row) => {
    setModalState(true);
    setRowData(row);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setModalState(true);
  };

  const handleDelete = async () => {
    setDelLoading(false);
    setOpenDelete(false);

    const result = await _delete_crop(deleteData?._id);
    if (result.code === 200) {
      const newList = cropList.filter(
        (target) => target._id !== deleteData?._id
      );

      const updatedList = newList.map((item, index) => ({
        ...item,
        order: index + 1,
      }));

      setCropList(updatedList);
      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      crop_ids: selectedIDS,
    };

    const result = await _delete_selected_crop(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_crop_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_crop_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      season: data.season,
      status: data.status,
    };

    if (postData.status === "all") {
      delete postData.status;
    }
    if (postData.season === "all") {
      delete postData.season;
    }
    setIsLoading(true);
    const result = await _list_of_crops(page, rowsPerPage, postData);

    if (result.code === 200) {
      setCropList(result.list_of_crop);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_crop_list();
  };

  useEffect(() => {
    get_crop_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Crops");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <FilterCrops
            filterInputs={filterInputs}
            handleFilterChange={handleFilterChange}
            onCloseDrawer={onCloseDrawer}
            handleResetFilter={handleResetFilter}
            handleFilterSubmit={searchFunction}
          />
        }
      />

      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Crop?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Crops?"}
        handleAgree={handleSelectedDelete}
      />

      <AddOrUpdateCrop
        modalState={modalState}
        setModalState={setModalState}
        rowData={rowData}
        cropList={cropList}
        setCropList={setCropList}
        setTotalCount={setTotalCount}
        get_crop_list={get_crop_list}
      />

      <div className="mt-2">
        <div className="d-flex justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={handleAgreeSelectedDelete}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="hugeicons:filter"
              />
            }
            onClick={onOpenDrawer}
            className="capitalized me-3 button-in-listing"
          >
            Filter
          </Button>
          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              setRowData(null);
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New Crop
          </Button>
        </div>

        <CustomTable
          data={cropList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default Crops;
