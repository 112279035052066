import React, { useEffect, useState } from "react";
import { Toolbar, Drawer, Divider, Box, Tooltip } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import NavSection from "./NavSection";
import { SideBarConfig } from "./SideBarConfig";
import { Logo } from "../../assets";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { _logout_api } from "../../DAL/Login/login";
import { useSnackbar } from "notistack";

const drawerWidth = 260;

function AppSideBar({ mobileOpen, handleDrawerToggle }) {
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  let nav_items = SideBarConfig({ setOpenLogout });

  const logoutConfirmed = async () => {
    setLogoutLoading(true);
    const result = await _logout_api();
    if (result.code === 200) {
      localStorage.removeItem("token");
      navigate("/login");
      setOpenLogout(false);
      setLogoutLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenLogout(false);
      setLogoutLoading(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function handleScroll() {
    const isScrolled =
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > window.innerHeight;
    setShowScrollArrow(isScrolled);
  }

  const drawer = (
    <>
      <div className="text-center p-4">
        <img src={Logo} alt={"Logo"} className="img-fluid login-img-min-max" />
      </div>
      <Divider />
      <NavSection navConfig={nav_items} />
    </>
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <DeleteConfirmation
        open={openLogout}
        isLoading={logoutLoading}
        setOpen={setOpenLogout}
        title={"Are you sure you want to Logout?"}
        handleAgree={logoutConfirmed}
        buttonText="Logout"
      />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "2px dashed #cecece80",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingBottom: {
            md: "100px",
          },
          minHeight: "100vh",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Outlet />
        <div
          className="scroll-top-community"
          style={{
            bottom: showScrollArrow ? "50px" : "-80px",
          }}
          onClick={handleScrollTop}
        >
          <Tooltip title="Scroll to top">
            <div>
              <i className="fa-solid fa-arrow-up"></i>
            </div>
          </Tooltip>
        </div>
      </Box>
      <footer className="footer mt-0 mt-md-0 d-none d-md-block"></footer>
    </>
  );
}

export default AppSideBar;
