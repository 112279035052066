import { invokeApi } from "../invokeApi";

export const _list_of_category = async (page, rows, data) => {
  const requestObj = {
    path: `api/category/list_of_category?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_category = async (data) => {
  const requestObj = {
    path: `api/category/add_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_category = async (_id, data) => {
  const requestObj = {
    path: `api/category/update_category/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_category = async (_id) => {
  const requestObj = {
    path: `api/category/delete_category/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_category_by_id = async (id) => {
  const requestObj = {
    path: `api/category/get_category_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _parent_category_list = async () => {
  const requestObj = {
    path: `api/category/parent_category_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_category = async (data) => {
  const requestObj = {
    path: `api/category/delete_selected_category`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _all_list_of_category = async () => {
  const requestObj = {
    path: `api/category/all_list_of_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
