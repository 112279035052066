import { invokeApi } from "../invokeApi";

export const _list_of_agri_consultancy = async (page, rows, data) => {
  const requestObj = {
    path: `api/agri_consultancy/list_of_agri_consultancy?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_agri_consultancy = async (data) => {
  const requestObj = {
    path: `api/agri_consultancy/add_agri_consultancy`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_agri_consultancy = async (_id, data) => {
  const requestObj = {
    path: `api/agri_consultancy/update_agri_consultancy/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_agri_consultancy = async (_id) => {
  const requestObj = {
    path: `api/agri_consultancy/delete_agri_consultancy/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_agri_consultancy_by_id = async (id) => {
  const requestObj = {
    path: `api/agri_consultancy/get_agri_consultancy_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_consultancy = async (data) => {
  const requestObj = {
    path: `api/agri_consultancy/delete_selected_consultancy`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
