import { invokeApi } from "../invokeApi";

export const _list_of_companies = async (page, rows, data) => {
  const requestObj = {
    path: `api/companies/list_of_companies?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_companies = async (data) => {
  const requestObj = {
    path: `api/companies/add_companies`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_companies = async (_id, data) => {
  const requestObj = {
    path: `api/companies/update_companies/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_companies = async (_id) => {
  const requestObj = {
    path: `api/companies/delete_companies/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_companies_by_id = async (id) => {
  const requestObj = {
    path: `api/companies/get_companies_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_companies = async (data) => {
  const requestObj = {
    path: `api/companies/delete_selected_companies`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
