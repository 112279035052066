import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { STATUS } from "../../utils/constant";
import moment from "moment";
import { useAdminContext } from "../../Hooks/AdminContext";
import { Avatar, Button } from "@mui/material";
import Iconify from "../../components/Iconify";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useSnackbar } from "notistack";
import { imageBaseUrl } from "../../config/config";
import CustomDrawer from "../../components/CustomDrawer";
import CircularLoader from "../../components/loaders/CircularLoader";
import AddOrUpdateFarmer from "./AddOrUpdateFarmer";
import FilterFarmer from "./components/FilterFarmer";
import {
  _delete_farmer_admin,
  _delete_selected_farmer_admin,
  _list_of_farmer_admin,
} from "../../DAL/Farmers/farmers";
import { _listing_apis_for_single_screen } from "../../DAL/Common/common";
import MultipleItemsShowList from "../../components/MultipleItemsShowList";
import CustomImageAvatar from "../../components/CustomImageAvatar";

const emptyFilter = {
  status: "all",
  district_id: "all",
  tehsil_id: "all",
  crop_id: "all",
};

const Farmers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setnavbarTitle } = useAdminContext();
  const [farmerList, setFarmerList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [openSelectedDelete, setOpenSelectedDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterInputs, setFilterInputs] = useState(emptyFilter);
  const [allDistrictList, setAllDistrictList] = useState([]);
  const [allCropList, setAllCropList] = useState([]);
  const [selectedCropsName, setSelectedCropsName] = useState([]);
  const [allTehsilList, setAllTehsilList] = useState([]);

  const [showMultiItems, setShowMultiItems] = useState(false);
  const [showMultiItemsType, setShowMultiItemsType] = useState("city");
  const [showMultiItemsData, setShowMultiItemsData] = useState(null);

  const TABLE_HEAD = [
    {
      id: "farmer_name_en",
      label: "FARMERS",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div
              onClick={() => handleClickDetail(row)}
              className="d-flex align-items-center mb-0 pointer"
            >
              <CustomImageAvatar
                imageUrl={row?.image?.thumbnail_2}
                altText={row.farmer_name_en}
              />
              <span className="capitalized">
                {row.farmer_name_en}{" "}
                {row.farmer_name_ur && (
                  <span className="font-family-urdu">
                    ( {row.farmer_name_ur} )
                  </span>
                )}
              </span>
            </div>
          </>
        );
      },
    },

    {
      id: "phone_number",
      label: "PHONE NUMBER",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <span>+</span>
            {row?.phone_number}
          </>
        );
      },
    },

    {
      id: "id_card_number",
      label: "ID CARD",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            {row?.id_card_number ? (
              <span>{row?.id_card_number}</span>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },

    {
      id: "joining_date",
      label: "JOINING DATE",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center mb-0">
              <span className="capitalized">
                {moment(row.joining_date).format("DD MMMM YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "city",
      label: "CITY",
      renderData: (row) => {
        const cities = row?.city ? row?.city : [];
        let firstCity = cities[0]?.tehsil_name_en;
        if (cities[0] && cities[0]?.tehsil_name_ur) {
          firstCity = cities[0]?.tehsil_name_ur;
        }
        const additionalCitiesCount =
          cities.length > 1 ? ` +${cities.length - 1}` : "";

        return (
          <div className="d-flex align-items-center mb-0 agri-consultancy-chip-count">
            <span className="agri-consultancy-chip">{firstCity}</span>
            <span
              className="pointer"
              onClick={() => multipleItemsShowList(cities, "city")}
            >
              {additionalCitiesCount}
            </span>
          </div>
        );
      },
    },
    {
      id: "total_land_area",
      label: "AREA",
      className: "typography-color-in-table",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center mb-0 capitalized">
              <span>
                {row?.total_land_area} {row?.land_area_unit}
              </span>
            </div>
          </>
        );
      },
    },
    {
      id: "crops",
      label: "CROPS",
      renderData: (row) => {
        const crops = row.crops;
        let firstCrop = crops[0]?.crop_name_en;
        if (crops[0]?.crop_name_ur) {
          firstCrop = crops[0]?.crop_name_ur;
        }

        const additionalCropsCount =
          crops.length > 1 ? ` +${crops.length - 1}` : "";

        return (
          <div className="d-flex align-items-center mb-0 sales-market-count">
            <span className="sales-market-chip font-family-urdu">
              {firstCrop}
            </span>
            <span
              className="pointer"
              onClick={() => multipleItemsShowList(crops, "crops")}
            >
              {additionalCropsCount}
            </span>
          </div>
        );
      },
    },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = STATUS.find((status) => status.value === row.status);
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    { id: "action", label: "ACTION", type: "action" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    const { target } = e;
    setFilterInputs({ ...filterInputs, [target.name]: target.value });
  };

  const handleResetFilter = () => {
    setFilterInputs(emptyFilter);
    get_farmer_list(emptyFilter);
  };

  const onOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const multipleItemsShowList = (row, type) => {
    console.log("row  __work", row);
    setShowMultiItemsType(type);
    setShowMultiItems(true);
    setShowMultiItemsData(row);
  };

  const handleClickDetail = (row) => {
    setModalState(true);
    setRowData(row);
    setSelectedCropsName(row?.crops);
    setIsDetail(true);
  };

  const handleClickEdit = (row) => {
    setIsDetail(false);
    setModalState(true);
    setRowData(row);
    setSelectedCropsName(row?.crops);
  };

  const handleAgreeDelete = (row) => {
    setDeleteData(row);
    setOpenDelete(true);
  };

  const handleAgreeSelectedDelete = () => {
    setOpenSelectedDelete(true);
  };

  const handleOpenModal = () => {
    setIsDetail(false);
    setRowData(null);
    setModalState(true);
    setSelectedCropsName([]);
  };

  const handleDelete = async () => {
    setOpenDelete(false);

    const result = await _delete_farmer_admin(deleteData?._id);
    if (result.code === 200) {
      const newList = farmerList.filter(
        (target) => target._id !== deleteData?._id
      );
      setFarmerList(newList);
      setTotalCount((prevTotalCount) => prevTotalCount - 1);

      setPage(
        newList.length <= 0
          ? (prevPage) => (prevPage > 1 ? prevPage - 1 : 0)
          : (prevPage) => prevPage
      );

      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSelectedDelete = async () => {
    const selectedIDS = selected.map((item) => item._id);

    let postData = {
      farmer_ids: selectedIDS,
    };

    const result = await _delete_selected_farmer_admin(postData);
    if (result.code === 200) {
      setSelected([]);
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setPage(0);
      setPageCount(1);
      get_farmer_list();
    } else {
      setDelLoading(false);
      setOpenSelectedDelete(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_all_district_tehsil_crop_list = async () => {
    if (
      allTehsilList.length > 0 &&
      allCropList.length > 0 &&
      allDistrictList.length > 0
    ) {
      return;
    }

    const result = await _listing_apis_for_single_screen({
      listing: ["district", "tehsil", "crop"],
    });
    console.log("result __result _listing_apis_for_single_screen", result);
    if (result.code === 200) {
      setAllTehsilList(result.tehsils);
      setAllDistrictList(result.districts);
      const convertedCropsObject = result.crops.map((crop) => {
        return {
          crop_id: crop._id,
          crop_name_en: crop.crop_name_en,
          crop_name_ur: crop.crop_name_ur,
        };
      });

      setAllCropList(convertedCropsObject);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_farmer_list = async (filterData) => {
    let data = filterData ? filterData : filterInputs;

    let postData = {
      search_text: searchText,
      status: data.status,
      district_id: data.district_id,
      city_id: data.tehsil_id,
      crop_id: data.crop_id,
    };

    console.log("postData   __check  _before", postData);
    if (postData.status === "all") {
      delete postData.status;
    }

    if (postData.district_id === "all") {
      delete postData.district_id;
    }

    if (postData.city_id === "all") {
      delete postData.city_id;
    }

    if (postData.crop_id === "all") {
      delete postData.crop_id;
    }

    console.log("postData   __check  _after", postData);

    setIsLoading(true);
    const result = await _list_of_farmer_admin(page, rowsPerPage, postData);

    if (result.code === 200) {
      setFarmerList(result.list_of_farmer);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    get_farmer_list();
  };

  useEffect(() => {
    get_farmer_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    setnavbarTitle("Farmers");
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <MultipleItemsShowList
        dataList={showMultiItemsData ? showMultiItemsData : []}
        modalState={showMultiItems}
        setModalState={setShowMultiItems}
        title={showMultiItemsType === "city" ? "All Cities" : "All Crops"}
        nameEn={
          showMultiItemsType === "city" ? "tehsil_name_en" : "crop_name_en"
        }
        nameUr={
          showMultiItemsType === "city" ? "tehsil_name_ur" : "crop_name_ur"
        }
      />

      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        pageTitle="Filter"
        componentToPassDown={
          <>
            <FilterFarmer
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              handleFilterChange={handleFilterChange}
              onCloseDrawer={onCloseDrawer}
              handleResetFilter={handleResetFilter}
              handleFilterSubmit={searchFunction}
              allDistrictList={allDistrictList}
              allCropList={allCropList}
              get_all_district_tehsil_crop_list={
                get_all_district_tehsil_crop_list
              }
            />
          </>
        }
      />

      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Farmer?"}
        handleAgree={handleDelete}
      />

      <DeleteConfirmation
        open={openSelectedDelete}
        setOpen={setOpenSelectedDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete Selected Farmer?"}
        handleAgree={handleSelectedDelete}
      />

      <AddOrUpdateFarmer
        modalState={modalState}
        setModalState={setModalState}
        rowData={rowData}
        setTotalCount={setTotalCount}
        allDistrictList={allDistrictList}
        allCropList={allCropList}
        selectedCropsName={selectedCropsName}
        setSelectedCropsName={setSelectedCropsName}
        farmerList={farmerList}
        setFarmerList={setFarmerList}
        isDetail={isDetail}
        get_all_district_tehsil_crop_list={get_all_district_tehsil_crop_list}
        allTehsilList={allTehsilList}
      />

      <div className="mt-2">
        <div className="d-flex   justify-content-end mb-3 add-button">
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={
                <Iconify
                  className="button-Iconify-in-listing"
                  icon="ant-design:delete-twotone"
                />
              }
              onClick={() => {
                handleAgreeSelectedDelete();
              }}
              className="capitalized me-3 button-in-listing"
            >
              Delete
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="hugeicons:filter"
              />
            }
            onClick={() => {
              onOpenDrawer();
            }}
            className="capitalized me-3 button-in-listing"
          >
            Filters
          </Button>

          <Button
            variant="contained"
            startIcon={
              <Iconify
                className="button-Iconify-in-listing"
                icon="eva:plus-fill"
              />
            }
            onClick={() => {
              handleOpenModal();
            }}
            className="capitalized button-in-listing"
          >
            Add New Farmer
          </Button>
        </div>

        <CustomTable
          data={farmerList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pagePagination={true}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          selected={selected}
          setSelected={setSelected}
          checkbox_selection={true}
        />
      </div>
    </>
  );
};

export default Farmers;
