import React, { useEffect } from "react";
import { useState } from "react";
import CustomPopover from "../../components/CustomPopover";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import { useSnackbar } from "notistack";
import {
  _update_agri_consultancy,
  _add_agri_consultancy,
} from "../../DAL/AgriConsultancy/agri_consultancy";
import { imageBaseUrl } from "../../config/config";

const emptyFormInputs = {
  consultancy_name_en: "",
  consultancy_name_ur: "",
  email: "",
  description: "",
};

const AddOrUpdateAgriConsultancy = ({
  modalState,
  setModalState,
  rowData,
  agriConsultancyList,
  setAgriConsultancyList,
  setTotalCount,
  allTehsilList,
  selectedCities,
  setSelectedCities,
  allServiceList,
  selectedServicies,
  setSelectedServicies,
  get_all_tehsil_service_list,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState();
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [previewUrl, setPreviewUrl] = useState("");
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  function handleFile(e) {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  }

  function handleRemoveImage() {
    setImage(null);
    setPreviewUrl("");
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const updateListObject = (newItem) => {
    const newList = agriConsultancyList.map((item) => {
      if (item._id === rowData?._id) {
        return newItem;
      }
      return item;
    });
    setAgriConsultancyList(newList);
  };

  const addNewObject = (newItem) => {
    setAgriConsultancyList((prevItemList) => [newItem, ...prevItemList]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
    setPreviewUrl("");
    setPhoneNumber("");
    setImage(null);
    setSelectedCities([]);
    setSelectedServicies([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formInputs.consultancy_name_en) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    if (!phoneNumber) {
      enqueueSnackbar("Phone Number is required", { variant: "error" });
      return;
    }

    if (!selectedServicies || selectedServicies.length === 0) {
      enqueueSnackbar("Service is required", { variant: "error" });
      return;
    }
    if (!selectedCities || selectedCities.length === 0) {
      enqueueSnackbar("City is required", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("status", isStatusActive);
    formData.append("email", formInputs.email);
    formData.append("description", formInputs.description);
    formData.append("city", JSON.stringify(selectedCities));
    formData.append("phone_number", phoneNumber);
    formData.append("services", JSON.stringify(selectedServicies));
    formData.append("consultancy_name_en", formInputs.consultancy_name_en);

    console.log("selectedCities   _test", selectedCities);
    console.log("selectedCities   _test", JSON.stringify(selectedCities));
    console.log("selectedServicies   _test", selectedServicies);
    console.log("selectedServicies   _test", JSON.stringify(selectedServicies));

    if (image) {
      formData.append("image", image);
    }

    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_agri_consultancy(rowData?._id, formData)
      : await _add_agri_consultancy(formData);

    if (result.code === 200) {
      const newData = result.consultancy;

      if (rowData?._id) {
        updateListObject(newData);
        setModalState(false);
      } else {
        addNewObject(newData);
        setModalState(false);
      }
      handleRefresh();
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      setIsStatusActive(rowData?.status);
      setFormInputs({
        description: rowData?.description,
        consultancy_name_en: rowData?.consultancy_name_en,
        consultancy_name_ur: rowData?.consultancy_name_ur,
        email: rowData?.email,
      });
      setPhoneNumber(rowData?.phone_number);
      setSelectedCities(rowData?.city ? rowData?.city : []);
      setSelectedServicies(rowData?.services ? rowData?.services : []);
      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
    }

    if (modalState) {
      get_all_tehsil_service_list();
    }
  }, [rowData?._id, modalState, rowData]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={
          rowData?._id ? "Edit Agri Consultancy" : "Add New Agri Consultancy"
        }
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        handleSubmit={handleSubmit}
        submitButtonLoader={submitButtonLoader}
        width={"600px"}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              previewUrl={previewUrl}
              handleFile={handleFile}
              handleChange={handleChange}
              handleRemoveImage={handleRemoveImage}
              allTehsilList={allTehsilList}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              allServiceList={allServiceList}
              selectedServicies={selectedServicies}
              setSelectedServicies={setSelectedServicies}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateAgriConsultancy;
