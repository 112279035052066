import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function ThemeConfig({ children }) {
  const themeOptions = {
    palette: {
      primary: {
        main: "#5792c9",
      },
      secondary: {
        main: "#5792c9",
      },
      text: {
        primary: "#000",
      },
    },
    typography: {
      fontFamily: "Manrope, Arial, sans-serif",
    },
  };

  const theme = createTheme(themeOptions);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
