import React from "react";
import { TextField, Avatar, IconButton, InputAdornment } from "@mui/material";
import { PhotoCamera, Close } from "@mui/icons-material";
import MultipleProductsSelectChip from "./MultipleProductsSelectChip";
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput";

const AddOrUpdateComponent = ({
  formInputs,
  previewUrl,
  handleFile,
  handleChange,
  allTehsilList,
  selectedCities,
  allCategoryList,
  setSelectedCities,
  handleRemoveImage,
  selectedCategories,
  setSelectedCategories,
  phoneNumber,
  setPhoneNumber,
}) => {
  return (
    <>
      <div className="popover-mid-container">
        <div className="spacing">
          <div className="align-center">
            <div style={{ position: "relative" }}>
              <label htmlFor="image" style={{ cursor: "pointer" }}>
                {previewUrl ? (
                  <Avatar
                    src={previewUrl}
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  />
                ) : (
                  <Avatar
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  >
                    <PhotoCamera fontSize="large" />
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={handleFile}
                    />
                  </Avatar>
                )}
              </label>

              {previewUrl && (
                <IconButton
                  onClick={handleRemoveImage}
                  className="image-remove-icon"
                >
                  <Close />
                </IconButton>
              )}

              <IconButton component="label" htmlFor="image">
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  hidden
                  onChange={handleFile}
                />
              </IconButton>
            </div>
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Name"
              value={formInputs.company_name}
              onChange={handleChange}
              name="company_name"
              className="text-field-border"
              required
            />
            <CustomPhoneInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              country="pk"
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="E-Mail"
              value={formInputs.email}
              onChange={handleChange}
              name="email"
              type="email"
              className="text-field-border"
              required
            />
            <TextField
              fullWidth
              label="Website URL"
              value={formInputs.website_url}
              onChange={handleChange}
              name="website_url"
              className="text-field-border"
              required
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allCategoryList}
              selectedProducts={selectedCategories}
              setSelectedProducts={setSelectedCategories}
              nameId="_id"
              nameEn="category_name_en"
              nameUr="category_name_ur"
              label="Categories *"
              placeholder="Add Category"
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allTehsilList}
              selectedProducts={selectedCities}
              setSelectedProducts={setSelectedCities}
              nameId="_id"
              nameEn="tehsil_name_en"
              nameUr="tehsil_name_ur"
              label="Cities *"
              placeholder="Add City"
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Description"
              value={formInputs.description}
              onChange={handleChange}
              name="description"
              className="text-field-border"
              multiline
              rows={3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
