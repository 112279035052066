import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";

const AddOrUpdateTehsilComponent = ({ formInputs, handleChange }) => {
  const [tehsilNameEnglishRequired, setTehsilNameEnglishRequired] =
    useState(true);
  const [tehsilNameUrduRequired, setTehsilNameUrduRequired] = useState(true);

  useEffect(() => {
    if (!formInputs.tehsil_name_en && !formInputs.tehsil_name_ur) {
      setTehsilNameEnglishRequired(true);
      setTehsilNameUrduRequired(true);
    } else if (!formInputs.tehsil_name_en) {
      setTehsilNameEnglishRequired(false);
      setTehsilNameUrduRequired(true);
    } else if (!formInputs.tehsil_name_ur) {
      setTehsilNameEnglishRequired(true);
      setTehsilNameUrduRequired(false);
    }
  }, [formInputs]);

  return (
    <>
      <div className="popover-mid-container">
        <h6 className="tehsil-label capitalized">
          {formInputs.district_name_en}{" "}
          {formInputs.district_name_ur && (
            <span className="font-family-urdu">
              ( {formInputs.district_name_ur} )
            </span>
          )}
        </h6>

        <div className="spacing">
          <div className="row-spacing">
            <TextField
              fullWidth
              label="Tehsil Name"
              value={formInputs.tehsil_name_en}
              onChange={handleChange}
              name="tehsil_name_en"
              className="text-field-border"
              required={tehsilNameEnglishRequired}
            />

            <CustomTextFieldUrdu
              label="تحصیل کا نام"
              value={formInputs.tehsil_name_ur}
              onChange={handleChange}
              name="tehsil_name_ur"
              placeholder="تحصیل کا نام"
              required={tehsilNameUrduRequired}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateTehsilComponent;
