import React, { useEffect } from "react";
import { useState } from "react";
import CustomPopover from "../../components/CustomPopover";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import { useSnackbar } from "notistack";
import { _add_district, _update_district } from "../../DAL/Districts/districts";

const emptyFormInputs = {
  district_name_en: "",
  district_name_ur: "",
};

const AddOrUpdateDistrict = ({
  modalState,
  setModalState,
  rowData,
  districtList,
  setDistrictList,
  setTotalCount,
  tehsilListModalState,
  tehsilModalState,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const updateListObject = (newItem) => {
    const newList = districtList.map((item) => {
      if (item._id === rowData?._id) {
        return newItem;
      }
      return item;
    });

    setDistrictList(newList);
  };

  const addNewObject = (newItem) => {
    setDistrictList((prevItemList) => [newItem, ...prevItemList]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };

  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formInputs.district_name_en && !formInputs.district_name_ur) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    let postData = {
      status: isStatusActive,
      district_name_en: formInputs.district_name_en,
      district_name_ur: formInputs.district_name_ur,
    };

    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_district(rowData?._id, postData)
      : await _add_district(postData);

    if (result.code === 200) {
      const newData = result.district;

      if (rowData?._id) {
        updateListObject(newData);
        setModalState(false);
      } else {
        addNewObject(newData);
        setModalState(false);
      }
      handleRefresh();
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      setIsStatusActive(rowData?.status);
      setFormInputs({
        district_name_en: rowData?.district_name_en,
        district_name_ur: rowData?.district_name_ur,
      });
    }
  }, [rowData?._id, modalState]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState, tehsilListModalState, tehsilModalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={rowData?._id ? "Edit District" : "Add New District"}
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        handleSubmit={handleSubmit}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        submitButtonLoader={submitButtonLoader}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              formInputs={formInputs}
              handleChange={handleChange}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateDistrict;
