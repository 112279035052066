import { invokeApi } from "../invokeApi";

export const _add_district = async (data) => {
  const requestObj = {
    path: `api/district/add_district`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_district = async (_id, data) => {
  const requestObj = {
    path: `api/district/update_district/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_district_by_id = async (id) => {
  const requestObj = {
    path: `api/district/get_district_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_of_districts = async (page, rows, data) => {
  const requestObj = {
    path: `api/district/list_of_districts?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_selected_district = async (data) => {
  const requestObj = {
    path: `api/district/delete_selected_district`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_district = async (_id) => {
  const requestObj = {
    path: `api/district/delete_district/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_tehsil_in_district = async (id, data) => {
  const requestObj = {
    path: `api/district/add_tehsil_in_district/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_tehsil_from_district = async (district_id, tehsil_id) => {
  const requestObj = {
    path: `api/district/delete_tehsil_from_district?district_id=${district_id}&tehsil_id=${tehsil_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_tehsil_in_district = async (
  district_id,
  tehsil_id,
  data
) => {
  const requestObj = {
    path: `api/district/update_tehsil_in_district?district_id=${district_id}&tehsil_id=${tehsil_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_of_tehsil = async (id) => {
  const requestObj = {
    path: `api/district/list_of_tehsil/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_all_district = async () => {
  const requestObj = {
    path: `api/district/get_all_district`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _all_list_of_tehsil = async () => {
  const requestObj = {
    path: `api/district/all_list_of_tehsil`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
