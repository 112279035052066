import { invokeApi } from "../invokeApi";

export const _list_of_farmer_admin = async (page, rows, data) => {
  const requestObj = {
    path: `api/farmer/list_of_farmer_admin?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_farmer_admin = async (data) => {
  const requestObj = {
    path: `api/farmer/add_farmer_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_farmer_admin = async (_id, data) => {
  const requestObj = {
    path: `api/farmer/update_farmer_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_farmer_admin = async (_id) => {
  const requestObj = {
    path: `api/farmer/delete_farmer_admin/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_farmer_by_id_admin = async (id) => {
  const requestObj = {
    path: `api/farmer/get_farmer_by_id_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_farmer_admin = async (data) => {
  const requestObj = {
    path: `api/farmer/delete_selected_farmer_admin`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
