import { invokeApi } from "../invokeApi";

export const _list_of_sales_market = async (page, rows, data) => {
  const requestObj = {
    path: `api/sales_market/list_of_sales_market?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_sales_market = async (data) => {
  const requestObj = {
    path: `api/sales_market/add_sales_market`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_sales_market = async (_id, data) => {
  const requestObj = {
    path: `api/sales_market/update_sales_market/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_sales_market = async (_id) => {
  const requestObj = {
    path: `api/sales_market/delete_sales_market/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_sales_market_by_id = async (id) => {
  const requestObj = {
    path: `api/sales_market/get_sales_market_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_sales_market = async (data) => {
  const requestObj = {
    path: `api/sales_market/delete_selected_sales_market`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
