import React from "react";
import { TextField, Avatar, IconButton, MenuItem } from "@mui/material";
import { PhotoCamera, Close } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";
import MultipleProductsSelectChip from "../../../components/MultipleProductsSelectChip";
import CustomPhoneInput from "../../../components/CustomPhoneInput/CustomPhoneInput";

const AddOrUpdateComponent = ({
  allCropList,
  selectedCropsName,
  setSelectedCropsName,
  formInputs,
  setFormInputs,
  previewUrl,
  handleFile,
  handleChange,
  handleRemoveImage,
  rowData,
  isDetail,
  allTehsilList,
  selectedCities,
  setSelectedCities,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [disabled, setDisabled] = useState(isDetail);
  const [nameEnglishRequired, setNameEnglishRequired] = useState(true);
  const [nameUrduRequired, setNameUrduRequired] = useState(true);

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13);

    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  useEffect(() => {
    setDisabled(rowData?._id && isDetail ? true : false);
  }, [isDetail]);

  useEffect(() => {
    if (!formInputs.farmer_name_en && !formInputs.farmer_name_ur) {
      setNameEnglishRequired(true);
      setNameUrduRequired(true);
    } else if (!formInputs.farmer_name_en) {
      setNameEnglishRequired(false);
      setNameUrduRequired(true);
    } else {
      setNameEnglishRequired(true);
      setNameUrduRequired(false);
    }
  }, [formInputs]);

  return (
    <>
      <div className="popover-mid-container">
        <div className="spacing">
          <>
            <div className="align-center">
              <div style={{ position: "relative" }}>
                <label htmlFor="image" style={{ cursor: "pointer" }}>
                  {previewUrl ? (
                    <Avatar
                      src={previewUrl}
                      sx={{
                        marginLeft: "25px",
                        width: "70px",
                        height: "70px",
                      }}
                      variant="square"
                      className="table-image-with-name"
                      disabled={disabled}
                    />
                  ) : !isDetail ? (
                    <Avatar
                      sx={{
                        marginLeft: "25px",
                        width: "70px",
                        height: "70px",
                      }}
                      variant="square"
                      className="table-image-with-name"
                      disabled={disabled}
                    >
                      <PhotoCamera fontSize="large" />
                      <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        hidden
                        onChange={handleFile}
                        disabled={disabled}
                      />
                    </Avatar>
                  ) : null}
                </label>

                {!disabled && previewUrl && (
                  <IconButton
                    onClick={handleRemoveImage}
                    className="image-remove-icon"
                  >
                    <Close />
                  </IconButton>
                )}

                <IconButton component="label" htmlFor="image">
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    hidden
                    onChange={handleFile}
                    disabled={rowData?._id && isDetail ? true : false}
                  />
                </IconButton>
              </div>
            </div>
            {!disabled && (
              <p class="image-recommend-size">
                Recommended Size (670 X 1000) ( "png", "jpg", "jpeg",
                "webp","GIF" )
              </p>
            )}
          </>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Name"
              value={formInputs.farmer_name_en}
              onChange={handleChange}
              name="farmer_name_en"
              className="text-field-border"
              required={nameEnglishRequired}
              disabled={disabled}
            />

            <CustomTextFieldUrdu
              label=" نام"
              value={formInputs.farmer_name_ur}
              onChange={handleChange}
              name="farmer_name_ur"
              labelDirection="left"
              disabled={disabled}
              required={nameUrduRequired}
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="ID Card Number"
              value={formInputs.id_card_number}
              onChange={handleCNICChange}
              // type="number"
              name="id_card_number"
              className="text-field-border"
              required
              autoComplete="off"
              inputProps={{
                pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                title:
                  "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
              }}
              disabled={disabled}
            />

            <CustomPhoneInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              country="pk"
              disabled={disabled}
            />
          </div>

          {rowData?._id ? null : (
            <div className="row-spacing">
              <TextField
                fullWidth
                label="Email"
                value={formInputs.email}
                onChange={handleChange}
                type="email"
                name="email"
                className="text-field-border"
                disabled={disabled}
                required
              />
            </div>
          )}

          <div className="row-spacing">
            <TextField
              fullWidth
              select
              label="Land Area Unit"
              value={formInputs.land_area_unit}
              onChange={handleChange}
              name="land_area_unit"
              className="text-field-border capitalized"
              disabled={disabled}
            >
              <MenuItem value="acre">Acre</MenuItem>
            </TextField>

            <TextField
              fullWidth
              label="Total Land Area"
              value={formInputs.total_land_area}
              onChange={handleChange}
              type="number"
              name="total_land_area"
              className="text-field-border"
              required
              disabled={disabled}
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allCropList}
              selectedProducts={selectedCropsName}
              setSelectedProducts={setSelectedCropsName}
              nameId="crop_id"
              nameEn="crop_name_en"
              nameUr="crop_name_ur"
              label="Crops *"
              placeholder="Add Crops"
              disabled={disabled}
            />
          </div>

          <div className="row-spacing">
            <MultipleProductsSelectChip
              productsObject={allTehsilList}
              selectedProducts={selectedCities}
              setSelectedProducts={setSelectedCities}
              nameId="_id"
              nameEn="tehsil_name_en"
              nameUr="tehsil_name_ur"
              label="Cities *"
              placeholder="Add City"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
