import React from "react";
import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

export const SideBarConfig = ({ setOpenLogout }) => {
  let sidebarMenus = [];

  const logout = async () => {
    setOpenLogout(true);
  };

  sidebarMenus.push({
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  });

  sidebarMenus.push({
    title: "Farmers",
    path: "/farmers",
    icon: getIcon("fluent-emoji-high-contrast:man-farmer"),
  });

  sidebarMenus.push({
    title: "Sales Market",
    path: "/sales-market",
    icon: getIcon("healthicons:market-stall-outline"),
  });
  sidebarMenus.push({
    title: "Services",
    path: "/services",
    icon: getIcon("fluent-mdl2:c-r-m-services"),
  });

  sidebarMenus.push({
    title: "Agri Consultancy",
    path: "/agri-consultancy",
    icon: getIcon("tabler:message"),
  });

  sidebarMenus.push({
    title: "Rental Machinery",
    path: "/rental-machinery",
    icon: getIcon("emojione-monotone:tractor"),
  });

  sidebarMenus.push({
    title: "Companies",
    path: "/companies",
    icon: getIcon("mingcute:building-6-line"),
  });
  sidebarMenus.push({
    title: "Districts",
    path: "/districts",
    icon: getIcon("uiw:map"),
  });

  sidebarMenus.push({
    title: "Crops",
    path: "/crops",
    icon: getIcon("fluent:leaf-two-16-regular"),
  });

  sidebarMenus.push({
    title: "Knowledge Bank",
    path: "/knowledge-bank",
    icon: getIcon("ri:bank-line"),
  });

  sidebarMenus.push({
    title: "Categories",
    path: "/categories",
    icon: getIcon("bx:category"),
  });

  sidebarMenus.push({
    title: "Logout",
    on_click: logout,
    icon: getIcon("humbleicons:logout"),
  });

  return sidebarMenus;
};
