import React, { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import AddOrUpdateComponent from "./components/AddOrUpdateComponent";
import CustomPopover from "../../components/CustomPopover";
import { imageBaseUrl } from "../../config/config";
import {
  _add_farmer_admin,
  _update_farmer_admin,
} from "../../DAL/Farmers/farmers";
import { generateRandomPassword } from "../../utils/constant";

const emptyFormInputs = {
  farmer_name_en: "",
  farmer_name_ur: "",
  description: "",
  land_area_unit: "acre",
  total_land_area: "",
  email: "",
};

const AddOrUpdateFarmer = ({
  rowData,
  allCropList,
  selectedCropsName,
  setSelectedCropsName,
  farmerList,
  setFarmerList,
  modalState,
  setModalState,
  setTotalCount,
  allDistrictList,
  isDetail = false,
  get_all_district_tehsil_crop_list,
  allTehsilList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [formInputs, setFormInputs] = useState(emptyFormInputs);
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");

  function handleFile(e) {
    const file = e.target.files[0];
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  }

  function handleRemoveImage() {
    setImage(null);
    setPreviewUrl("");
  }
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const updateListObject = (newItem) => {
    const newList = farmerList.map((item) => {
      if (item._id === rowData?._id) {
        return newItem;
      }
      return item;
    });
    setFarmerList(newList);
  };

  const addNewObject = (newItem) => {
    setFarmerList((prevItemList) => [newItem, ...prevItemList]);
    setTotalCount((prevTotalCount) => prevTotalCount + 1);
  };
  const handleRefresh = () => {
    setIsStatusActive(true);
    setFormInputs(emptyFormInputs);
    setPreviewUrl("");
    setPhoneNumber("");
    setImage(null);
    setSelectedCropsName([]);
    setSelectedCities([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formInputs.farmer_name_en && !formInputs.farmer_name_ur) {
      enqueueSnackbar("Name is required", { variant: "error" });
      return;
    }

    if (!formInputs.id_card_number) {
      enqueueSnackbar("ID Card Number is required", { variant: "error" });
      return;
    }

    if (!phoneNumber) {
      enqueueSnackbar("Phone Number is required", { variant: "error" });
      return;
    }

    if (!selectedCropsName || selectedCropsName.length === 0) {
      enqueueSnackbar("Crop is required", { variant: "error" });
      return;
    }

    if (!selectedCities || selectedCities.length === 0) {
      enqueueSnackbar("City is required", { variant: "error" });
      return;
    }

    const formData = new FormData();
    formData.append("status", isStatusActive);
    formData.append("phone_number", phoneNumber);
    formData.append("crops", JSON.stringify(selectedCropsName));
    formData.append("city", JSON.stringify(selectedCities));
    formData.append("total_land_area", formInputs.total_land_area);
    formData.append("farmer_name_en", formInputs.farmer_name_en);
    formData.append("farmer_name_ur", formInputs.farmer_name_ur);
    formData.append("land_area_unit", formInputs.land_area_unit);
    formData.append("id_card_number", formInputs.id_card_number);

    if (!rowData?._id) {
      formData.append("email", formInputs.email);

      const randomPassword = generateRandomPassword();
      formData.append("password", randomPassword);
    }

    if (image) {
      formData.append("image", image);
    }

    setSubmitButtonLoader(true);
    const result = rowData?._id
      ? await _update_farmer_admin(rowData?._id, formData)
      : await _add_farmer_admin(formData);

    if (result.code === 200) {
      const newData = result.farmer;

      if (rowData?._id) {
        updateListObject(newData);
      } else {
        addNewObject(newData);
      }
      handleRefresh();
      setModalState(false);
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setSubmitButtonLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (rowData?._id) {
      setIsStatusActive(rowData?.status);
      setFormInputs({
        ...formInputs,
        farmer_name_en: rowData?.farmer_name_en,
        farmer_name_ur: rowData?.farmer_name_ur,
        description: rowData?.description,
        total_land_area: rowData?.total_land_area,
        land_area_unit: rowData?.land_area_unit,
        id_card_number: rowData?.id_card_number,
      });
      setPhoneNumber(rowData?.phone_number);
      setSelectedCities(rowData?.city ? rowData?.city : []);
      setPreviewUrl(
        rowData?.image ? imageBaseUrl + rowData?.image?.thumbnail_2 : ""
      );
    }

    if (modalState) {
      get_all_district_tehsil_crop_list();
    }
  }, [rowData?._id, modalState, rowData]);

  useEffect(() => {
    if (!modalState) {
      handleRefresh();
    }
  }, [modalState]);

  return (
    <>
      <CustomPopover
        isOpenPop={modalState}
        isClosePop={setModalState}
        title={
          rowData?._id && isDetail
            ? "Farmer Detail"
            : rowData?._id
            ? "Edit Farmer"
            : "Add New Farmer"
        }
        submitButtonText={rowData?._id ? "Update" : "Submit"}
        showStatus={true}
        showAllButtons={!isDetail}
        isStatusActive={isStatusActive}
        setIsStatusActive={setIsStatusActive}
        handleSubmit={handleSubmit}
        submitButtonLoader={submitButtonLoader}
        width={"600px"}
        componentToPassDown={
          <>
            <AddOrUpdateComponent
              rowData={rowData}
              allCropList={allCropList}
              selectedCropsName={selectedCropsName}
              setSelectedCropsName={setSelectedCropsName}
              formInputs={formInputs}
              setFormInputs={setFormInputs}
              previewUrl={previewUrl}
              handleFile={handleFile}
              handleChange={handleChange}
              allDistrictList={allDistrictList}
              handleRemoveImage={handleRemoveImage}
              isDetail={isDetail}
              allTehsilList={allTehsilList}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </>
        }
      />
    </>
  );
};

export default AddOrUpdateFarmer;
