import { invokeApi } from "../invokeApi";

export const _admin_login_api = async (data) => {
  const requestObj = {
    path: `api/admin/login_admin`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _email_verification_api = async (data) => {
  const requestObj = {
    path: `api/app_api/email_verification`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _code_verification_api = async (data) => {
  const requestObj = {
    path: `api/app_api/code_verification`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _reset_password_api = async (data) => {
  const requestObj = {
    path: `api/app_api/reset_password`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _admin_init_with_token = async () => {
  let requestObj = {
    path: `api/admin/admin_init_with_token`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _logout_api = async () => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
