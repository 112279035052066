import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { _get_all_district } from "../../../DAL/Districts/districts";
import { useSnackbar } from "notistack";

const FilterDistricts = ({
  filterInputs,
  handleFilterChange,
  handleResetFilter,
  onCloseDrawer,
  handleFilterSubmit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [districtList, setDistrictList] = useState([]);

  const get_drop_down_district_list = async () => {
    const result = await _get_all_district();

    if (result.code === 200) {
      setDistrictList(result.district);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_drop_down_district_list();
  }, []);

  return (
    <div className="container mt-4">
      <div className="spacing">
        <div className="row-spacing">
          <TextField
            fullWidth
            label="District"
            select
            value={filterInputs.district_id}
            onChange={handleFilterChange}
            name="district_id"
            className="text-field-border capitalized"
          >
            <MenuItem value="all">All</MenuItem>
            {districtList.map((district) => (
              <MenuItem
                className="capitalized"
                key={district._id}
                value={district._id}
              >
                <span className="capitalized">
                  {district.district_name_en}
                  {district.district_name_ur && (
                    <span className="font-family-urdu">
                      ( {district.district_name_ur} )
                    </span>
                  )}
                </span>
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="row-spacing">
          <TextField
            fullWidth
            label="Status"
            select
            value={filterInputs.status}
            onChange={handleFilterChange}
            name="status"
            className="text-field-border"
          >
            <MenuItem value="all">None</MenuItem>
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              onCloseDrawer();
              handleFilterSubmit(e);
            }}
          >
            <Iconify
              icon="material-symbols:filter-alt"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Filter
          </button>
        </div>

        <div className="col-12 mt-3">
          <button
            className="small-contained-button  w-100"
            onClick={(e) => {
              handleResetFilter();
              onCloseDrawer();
            }}
          >
            <Iconify
              icon="material-symbols:clear-all-rounded"
              style={{ width: 18, height: 18, marginRight: 15 }}
            />
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterDistricts;
