import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginImage, loginLogo } from "../../assets";
import LoginForm from "./components/LoginForm";
import EmailForm from "./components/EmailForm";
import PinCodeForm from "./components/PinCodeForm";
import ConfrimPassword from "./components/ConfrimPassword";
import { useSnackbar } from "notistack";
import {
  _code_verification_api,
  _email_verification_api,
  _reset_password_api,
} from "../../DAL/Login/login";

const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [adminEmail, setAdminEmail] = useState("");

  const handleMove = () => {
    const handle_move = document.getElementById("handle_move");
    if (handle_move) {
      handle_move.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleSubmitEmail = async (_email) => {
    setAdminEmail(_email);
    let postData = {
      email: _email,
      type: 0,
    };

    const result = await _email_verification_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setFormState(1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmitPinCode = async (pinCode) => {
    let postData = {
      email: adminEmail,
      verification_code: pinCode,
      type: 0,
    };

    const result = await _code_verification_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setFormState(2);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmitNewPassword = async (newPassword, confirmPassword) => {
    let postData = {
      email: adminEmail,
      password: newPassword,
      confirm_password: confirmPassword,
      type: 0,
    };

    const result = await _reset_password_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setFormState(-1);
      navigate("/login");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="full-height-container">
        <div className="row g-0">
          <div className="col-md-6 col-lg-4  " id="handle_move">
            <div className="p-5 card-body">
              <div className="d-flex flex-column my-4 mb-5 mt-4 ">
                <div className="text-center ">
                  <img
                    src={loginLogo}
                    className="img-fluid login-img-min-max"
                    alt="Login Logo"
                  />
                </div>
              </div>

              <>
                {formState === 0 ? (
                  <EmailForm
                    handleSubmitEmail={handleSubmitEmail}
                    setErrorMessage={setErrorMessage}
                    handleMove={handleMove}
                    isLoading={isLoading}
                    setFormState={setFormState}
                  />
                ) : formState === 1 ? (
                  <PinCodeForm
                    handleSubmitPinCode={handleSubmitPinCode}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    handleAlertClose={handleAlertClose}
                    setErrorMessage={setErrorMessage}
                    handleMove={handleMove}
                    setFormState={setFormState}
                  />
                ) : formState === 2 ? (
                  <ConfrimPassword
                    handleSubmitNewPassword={handleSubmitNewPassword}
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    handleAlertClose={handleAlertClose}
                    setErrorMessage={setErrorMessage}
                    handleMove={handleMove}
                    setFormState={setFormState}
                  />
                ) : (
                  <LoginForm setFormState={setFormState} />
                )}
              </>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-8 overlay-container d-none d-md-block"
            style={{
              backgroundImage: `url(${loginImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Login;
