import React, { useEffect } from "react";
import SummaryCard from "./components/SummaryCard";
import { useAdminContext } from "../../Hooks/AdminContext";
import ReactApexChartDashboard from "./components/ReactApexChartDashboard";

const Dashboard = () => {
  const { setnavbarTitle } = useAdminContext();

  const DUMMY_DATA = [
    {
      color: "#5792c9",
      title: "Total Farmers",

      count: "450",
      icon: "ri:group-line",
    },
    {
      color: "#5792c9",
      title: "Total Companies",
      count: "50",

      icon: "mingcute:building-6-line",
    },
    {
      color: "#5792c9",
      title: "Top District ",
      count: "Multan",
      icon: "uiw:map",
    },
    {
      color: "#5792c9",
      title: "Total Area owned by Farmers",
      count: "63,786 Acres",
      icon: "arcticons:50-us-states-map",
    },
  ];

  useEffect(() => {
    setnavbarTitle("DashBoard");
  }, []);

  return (
    <>
      <div className="row">
        {DUMMY_DATA.map((item, i) => (
          <div key={i} className="col-12 col-md-6 col-lg-3">
            <SummaryCard
              color={item.color}
              title={item.title}
              count={item.count}
              icon={item.icon}
            />
          </div>
        ))}
      </div>

      <div className="row mt-1">
        <ReactApexChartDashboard />
      </div>
    </>
  );
};

export default Dashboard;
