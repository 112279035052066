import React, { useEffect, useState } from "react";
import { TextField, Avatar, IconButton, MenuItem } from "@mui/material";
import { Close, PhotoCamera } from "@mui/icons-material";
import CustomTextFieldUrdu from "../../../components/CustomTextFieldUrdu/CustomTextFieldUrdu";

const AddOrUpdateComponent = ({
  formInputs,
  previewUrl,
  handleFile,
  handleChange,
  parentCategoryList,
  rowDataID,
  handleRemoveImage,
}) => {
  const [categoryNameEnglishRequired, setCategoryNameEnglishRequired] =
    useState(true);
  const [categoryNameUrduRequired, setCategoryNameUrduRequired] =
    useState(true);

  useEffect(() => {
    if (!formInputs.category_name_en && !formInputs.category_name_ur) {
      setCategoryNameEnglishRequired(true);
      setCategoryNameUrduRequired(true);
    } else if (!formInputs.category_name_en) {
      setCategoryNameEnglishRequired(false);
      setCategoryNameUrduRequired(true);
    } else if (!formInputs.category_name_ur) {
      setCategoryNameEnglishRequired(true);
      setCategoryNameUrduRequired(false);
    }
  }, [formInputs]);

  return (
    <>
      <div className="popover-mid-container">
        {/* <form autoComplete="off" encType="multipart/form-data"> */}
        <div className="spacing">
          <div className="align-center">
            <div style={{ position: "relative" }}>
              <label htmlFor="image" style={{ cursor: "pointer" }}>
                {previewUrl ? (
                  <Avatar
                    src={previewUrl}
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  />
                ) : (
                  <Avatar
                    sx={{ marginLeft: "25px", width: "70px", height: "70px" }}
                    variant="square"
                    className="table-image-with-name"
                  >
                    <PhotoCamera fontSize="large" />
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      hidden
                      onChange={handleFile}
                    />
                  </Avatar>
                )}
              </label>

              {previewUrl && (
                <IconButton
                  onClick={handleRemoveImage}
                  className="image-remove-icon"
                >
                  <Close />
                </IconButton>
              )}

              <IconButton component="label" htmlFor="image">
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  hidden
                  onChange={handleFile}
                />
              </IconButton>
            </div>
          </div>
          <p class="image-recommend-size">
            Recommended Size (670 X 1000) ( "png", "jpg", "jpeg", "webp","GIF" )
          </p>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Category Name"
              value={formInputs.category_name_en}
              onChange={handleChange}
              name="category_name_en"
              className="text-field-border"
              required={categoryNameEnglishRequired}
            />

            <CustomTextFieldUrdu
              label="قسم کا نام"
              value={formInputs.category_name_ur}
              onChange={handleChange}
              name="category_name_ur"
              placeholder="قسم کا نام"
              required={categoryNameUrduRequired}
            />
          </div>

          <div className="row-spacing">
            <TextField
              fullWidth
              label="Choose Parent Category"
              select
              value={formInputs.parent_category_id}
              onChange={handleChange}
              name="parent_category_id"
              className="text-field-border capitalized"
              required
            >
              <MenuItem value="none">None</MenuItem>
              {parentCategoryList
                .filter((category) => category._id !== rowDataID)
                .map((category) => (
                  <MenuItem
                    className="capitalized"
                    key={category._id}
                    value={category._id}
                  >
                    <span>
                      {category.category_name_en}{" "}
                      {category.category_name_ur && (
                        <span className="font-family-urdu">
                          ( {category.category_name_ur} )
                        </span>
                      )}
                    </span>
                  </MenuItem>
                ))}
            </TextField>

            {rowDataID && (
              <TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                type="number"
                className="text-field-border"
                required
              />
            )}
          </div>
        </div>
        {/* </form> */}
      </div>
    </>
  );
};

export default AddOrUpdateComponent;
