export const STATUS = [
  {
    name: "Active",
    value: true,
    class: "crops-active-status",
  },
  {
    name: "Inactive",
    value: false,
    class: "crops-inactive-status",
  },
];

export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + " ... ";
  }
  return str;
}

export function generateRandomPassword(length = 8) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
}
