import { invokeApi } from "../invokeApi";

export const _list_of_rental_machineries = async (page, rows, data) => {
  const requestObj = {
    path: `api/rental_machinery/list_of_rental_machineries?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_rental_machinery = async (data) => {
  const requestObj = {
    path: `api/rental_machinery/add_rental_machinery`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_rental_machinery = async (_id, data) => {
  const requestObj = {
    path: `api/rental_machinery/update_rental_machinery/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_rental_machinery = async (_id) => {
  const requestObj = {
    path: `api/rental_machinery/delete_rental_machinery/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_rental_machinery_by_id = async (id) => {
  const requestObj = {
    path: `api/rental_machinery/get_rental_machinery_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_selected_rental_machinery = async (data) => {
  const requestObj = {
    path: `api/rental_machinery/delete_selected_rental_machinery`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
