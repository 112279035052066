import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { imageBaseUrl } from "../config/config";

const CustomImageAvatar = ({ imageUrl, altText }) => {
  const [reloadCount, setReloadCount] = useState(0);
  const [imageSrc, setImageSrc] = useState(
    `${imageBaseUrl}${imageUrl}?timestamp=${new Date().getTime()}`
  );

  const onErrorReloadImage = () => {
    if (reloadCount <= 5) {
      setImageSrc(
        `${imageBaseUrl}${imageUrl}?timestamp=${new Date().getTime()}`
      );
      setReloadCount(reloadCount + 1);
    }
  };

  const reloadImage = () => {
    setImageSrc(`${imageBaseUrl}${imageUrl}?timestamp=${new Date().getTime()}`);
    setReloadCount(0);
  };

  useEffect(() => {
    reloadImage();
  }, [altText, imageUrl]);

  return (
    <Avatar
      src={imageSrc}
      alt={altText}
      variant="square"
      className="table-image-with-name"
      onError={onErrorReloadImage}
    />
  );
};

export default CustomImageAvatar;
