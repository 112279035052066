import React, { createContext, useContext, useEffect, useState } from "react";

const AdminContext = createContext();

export const useAdminContext = () => useContext(AdminContext);
export function ContextAdmin({ children }) {
  const [navbarTitle, setnavbarTitle] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserInfo(JSON.parse(user));
    }
  }, [localStorage.getItem("user")]);

  const collection = {
    navbarTitle,
    setnavbarTitle,
    setUserInfo,
    userInfo,
  };

  return (
    <AdminContext.Provider value={collection}>{children}</AdminContext.Provider>
  );
}
