import { useRef, useState } from "react";
import { Box, Avatar } from "@mui/material";
import { UserIcon } from "../../assets";
import ProfilePopover from "../../components/ProfilePopover";
import Iconify from "../../components/Iconify";
import { useAdminContext } from "../../Hooks/AdminContext";
import { imageBaseUrl } from "../../config/config";
// ----------------------------------------------------------------------

export default function AccountPopover({ setIsLoading }) {
  const anchorRef = useRef(null);
  const { userInfo } = useAdminContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mt-4 px-0">
      <div className="d-flex justify-content-between">
        <div className="left-item">
          {/* <Box className="notification-container pointer">
            <Iconify className="notification-bell" icon={"line-md:bell-loop"} />
            <div className="notification-dot"></div>
          </Box> */}
        </div>
        <div className="right-item ms-3">
          <div className="profile-container" onClick={handleOpen}>
            <div className="profile-avatar">
              <Avatar
                variant="rounded"
                sx={{ width: 40, height: 40 }}
                src={
                  userInfo?.image
                    ? imageBaseUrl + userInfo?.image?.thumbnail_2
                    : UserIcon
                }
              />
            </div>
            <div className="profile-info">
              <span className="user-name">{userInfo?.full_name}</span>
              <span className="user-role">
                {userInfo?.user_id?.type == 0 ? "Admin" : "User"}
              </span>
            </div>

            <span className="chevron-icon pointer">
              <i
                className={`ms-3 me-2 fa-solid ${
                  open ? "fa-chevron-up" : "fa-chevron-down"
                } `}
              ></i>
            </span>
          </div>
        </div>
      </div>

      <ProfilePopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220, borderRadius: 5, border: "1px solid #cecece" }}
      >
        {
          <Box sx={{ my: 1.5, px: 2.5 }} className="profile-container-view">
            <Avatar
              variant="rounded"
              sx={{
                width: 70,
                height: 70,
                mx: "auto",
                borderRadius: 3,
              }}
              src={
                userInfo?.image
                  ? imageBaseUrl + userInfo?.image?.thumbnail_1
                  : UserIcon
              }
            />
            <div className="profile-info-view">
              <span className="user-name-view">{userInfo?.full_name}</span>
              <span className="user-email-view">
                {" "}
                {userInfo?.user_id?.email}
              </span>
              <span className="user-role-view">
                {userInfo?.user_id?.type == 0 ? "Admin" : "User"}
              </span>
            </div>
          </Box>
        }
      </ProfilePopover>
    </div>
  );
}
